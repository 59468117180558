import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PaneChatTabs from '../modules/Pane/PaneChatTabs'; 
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import ChatMessages from '../modules/Chats/components/ChatMessages'; 
import ProfileMore from '../modules/Profile/ProfileMore'; 
import { ProfileChatStart, IntegrationChatHeader } from '../components/Profile/Profile'; 
import {selectUser} from '../modules/User/model/selectors/selectUser'
import {selectIntegrations} from '../modules/Integrations/model/selectors/selectIntegrations'
import {selectIntegrationsApps} from '../modules/Integrations/model/selectors/selectIntegrationsApps'
import { integrationsList } from '../modules/Integrations/model/action/list'
import { integrationsApps } from '../modules/Integrations/model/action/apps'
import InputTextMulti from "../components/ComponentForm/InputTextMulti"
import InputText from "../components/ComponentForm/InputText"
import TextareaMulti from "../components/ComponentForm/TextareaMulti"
import Textarea from "../components/ComponentForm/Textarea"


export default function Integrations() {
    const dispatch = useDispatch(); // Получаем функцию dispatch из Redux

    let authorized = 0
    let user_id = 0
    let username = 0
  
    let cookie_split = document.cookie.split(';');
    for (let i = 0; i < cookie_split.length; i++) {
      let cookie_name_split = cookie_split[i].split('=');  
      if(cookie_name_split[0].replace(/\s+/g, '') === "authorized"){
        authorized = cookie_name_split[1]
      }
      if(cookie_name_split[0].replace(/\s+/g, '') === "user_id"){
        user_id = cookie_name_split[1]
      }
      if(cookie_name_split[0].replace(/\s+/g, '') === "username"){
        username = cookie_name_split[1]
      }
    }

    const integrations_list = useSelector(selectIntegrations)
    const integrations_apps = useSelector(selectIntegrationsApps)
    // console.log(integrations_apps)

    const [loadProfileChatStart, setProfileChatStart] = useState(null);
    const [loadProfileInfo, setProfileInfo] = useState(false);
    
    const handleProfileChatStart = (value) => {
        // console.log(integrations_list[value])
        setProfileChatStart(value);
    }

    const handleProfileInfo = (value) => {
        setProfileInfo(value);
    }

    useEffect(() => {
        const data = {
            user_id: user_id
        };
        dispatch(integrationsList(data))
        dispatch(integrationsApps({}))
    }, [dispatch, user_id]);

    // console.log(integrations_list[loadProfileChatStart][0]['existing_table'])

    return (
        <>
        <div className="side-menu-tab">
            <div className="tab-content">
                {integrations_list === null || integrations_apps === null ? 
                    <>
                        <Stack spacing={2}>
                            <Skeleton variant="rounded" width={"100%"} height={24} />
                            <Skeleton variant="rounded" width={"100%"} height={56} />
                        </Stack>
                        <Stack spacing={2} direction="row" sx={{mt: 3}}>
                            <Skeleton variant="rounded" width={"100%"} height={56} />
                            <Skeleton variant="rounded" width={"100%"} height={56} />
                        </Stack>
                        <Stack spacing={2} sx={{mt: 3}}>
                            {Array(3).fill(null).map((_, index) => (
                                <Skeleton key={index} variant="rounded" width={"100%"} animation="wave" height={124} />
                            ))}
                        </Stack>
                    </>
                :
                    <>
                        <PaneChatTabs 
                            title='Интеграции'
                            placeholder='Поиск по базе интеграций'
                            btn1={"Активные"}
                            btn2={"Добавить"}
                            length={(integrations_list ? integrations_list.length : 0)}
                            list={integrations_list}
                            apps={integrations_apps}
                            loadProfileChatStart={loadProfileChatStart}
                            onClickHandlerChangeProfileChat={(value) => handleProfileChatStart(value)}
                        />
                    </>
                }
                
            </div>
        </div>
        {integrations_list !== null && integrations_apps !== null && <>
            <div className={"layout-content" + (loadProfileChatStart !== null ? ' active' : '')}>
                {loadProfileChatStart === null ? 
                <ProfileChatStart user_id={user_id} username={username}/> :
                <div className="chat-box">
                    <div className="container">
                    <div className="chat-wrap">
                        <IntegrationChatHeader 
                            loadProfileInfo={loadProfileInfo}
                            profile={integrations_list[loadProfileChatStart][0]['existing_table']}
                            photo={integrations_list[loadProfileChatStart][0]['photo']}
                            onClickHandlerChangeProfileInfo={(value) => handleProfileInfo(value)}
                            onClickHandlerChangeProfileChat={(value) => handleProfileChatStart(value)}
                        />
                        <div className="scrollbar-wrap bottom-scroll">
                            <div className="chat-content">
                                <div className="my-4">
                                    <h6>Настройки интеграции</h6>
                                    <p>{integrations_list[loadProfileChatStart][0]['existing']['title']} {integrations_list[loadProfileChatStart][0]['existing']['application']}</p>
                                </div>
                                <div className="group-form" style={{maxWidth: "480px"}}>
                                    <form action="#">
                                        {integrations_list[loadProfileChatStart][0]['existing_setting'].map((item, index) => (
                                            <div key={index}>
                                                {item?.type_field == 'input_text_multi' && <InputTextMulti item={item} value={integrations_list[loadProfileChatStart][0]['existing_table'][item?.name.toLowerCase()]}/>}

                                                {item?.type_field == 'input_text' && <InputText item={item} value={integrations_list[loadProfileChatStart][0]['existing_table'][item?.name.toLowerCase()]}/>}

                                                {item?.type_field == 'textarea_multi' && <TextareaMulti item={item} value={integrations_list[loadProfileChatStart][0]['existing_table'][item?.name.toLowerCase()]}/>}

                                                {item?.type_field == 'textarea' && <Textarea item={item} value={integrations_list[loadProfileChatStart][0]['existing_table'][item?.name.toLowerCase()]}/>}
                                            </div>
                                        ))}
                                        
                                        {/* <div className="input-group">
                                        <input type="email" className="form-control" placeholder="Email" value="name@example.com" />
                                        <span className="input-group-text"><i className="feather-mail"></i></span>
                                        </div>
                                        <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Phone" value="1234567889" />
                                        <span className="input-group-text"><i className="feather-phone"></i></span>
                                        </div>
                                        <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Location" value="Parkway Drive, USA" />
                                        <span className="input-group-text"><i className="feather-map-pin"></i></span>
                                        </div>
                                        <div className="input-group">
                                        <textarea className="form-control" cols="30" rows="4" placeholder="Write bio">Web Designer</textarea>
                                        </div> */}
                                        <div className="mb-4">
                                            <button type="submit" className="theme-btn w-100" style={{maxWidth: "70%"}} disabled>Сохранить</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* <div className="chat-bottom">
                            <div className="chat-form">
                                <form action="#">
                                    <div className="chat-form-wrap">
                                        <div className="microphone">
                                            <button type="button"><i className="feather-mic"></i></button>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Type your message..." />
                                        </div>
                                        <div className="emoji">
                                            <button type="button"><i className="feather-smile"></i></button>
                                        </div>
                                        <div className="chat-file">
                                            <button type="button" className="file-btn"><i className="feather-paperclip"></i></button>
                                            <input type="file" className="file-input" />
                                        </div>
                                        <div className="send-btn">
                                            <button type="submit"><i className="feather-send"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> */}
                    </div>
                    </div>
                </div>}
            </div>
            {loadProfileChatStart !== null && 
            <div className={"chat-user-more offcanvas offcanvas-end" + (loadProfileInfo ? ' show' : '')} data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="chatUserMore" aria-labelledby="chatUserMoreLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="chatUserMoreLabel">Информация</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>handleProfileInfo(!loadProfileInfo)}><i className="far fa-xmark"></i></button>
                </div>
                <div className="offcanvas-body scrollbar-wrap">
                    <div className="chat-user-info">
                        <ProfileMore 
                            profile={integrations_list[loadProfileChatStart][0]['existing_table']}
                            photo={integrations_list[loadProfileChatStart][0]['photo']}
                            loadProfileInfo={loadProfileInfo}
                            onClickHandlerChangeProfileInfo={(value) => handleProfileInfo(value)}
                        />
                    </div>
                </div>
            </div>}
        </>}
    </>
    )
}