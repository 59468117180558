import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
// import 'moment/locale/ru';
// import CardContacts from '../../../components/Card/CardContacts';

export default function integrationList(props) {

    const { item, onClickHandlerChangeProfileChat, idx } = props;

    // console.log(item)

    return <>
            <div className="item" style={{
                background: (item[0]["existing_table"]?.active != 'online' ? "#ebebeb" : "#ffffff")
            }}>
                <div className="content">
                    <span className="group-avatar">
                        <div className="avatar">
                            {item[0]["photo"] ? 
                                <img src={item[0]["photo"]} alt="" /> 
                                : 
                                <span className="avatar-text"><i className="feather-rss"></i></span>
                            }
                            {/* <img src={`assets/img/logo/${item[0]["existing"]?.application}.svg`} alt={item[0]["existing"]?.application} /> */}
                        </div>
                    </span>
                    <div className="info">
                        <p className="text mb-1">{item[0]["existing"]?.title} {item[0]["existing"]?.application}</p>
                        <h6 className="title">{item[0]["existing_table"]?.name || item[0]["existing_table"]?.account_id}</h6>
                        {/* <h6 className="title"><a href={`https://t.me/${item[0]["existing_table"]?.username}`} target="_blank">{item[0]["existing_table"]?.name} {item[0]["existing_table"]?.account_id}</a></h6> */}
                        {/* <p className="text">{item[0]["existing_table"]?.username && <a href={`https://t.me/${item[0]["existing_table"]?.username}`} target="_blank" style={{fontSize: "13px"}}>@{item[0]["existing_table"]?.name}</a>}</p> */}
                    </div>
                    <div className="action">
                        <div className="dropdown">
                            <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>{onClickHandlerChangeProfileChat(idx)}}>
                                <i className="feather-more-vertical"></i>
                            </button>
                            {/* <ul className="show dropdown-menu" style={{
                                position: "absolute",
                                right: "20%"
                            }}>
                                <li><a className="dropdown-item" href="#"><i className="feather-edit-2"></i>Edit</a></li>
                                <li><a className="dropdown-item" href="#"><i className="feather-bell"></i>Mute</a></li>
                                <li>
                                <hr className="dropdown-divider" />
                                </li>
                                <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="bottom-info">
                        <span className="group-status"><i className={`fas fa-circle ${(item[0]["existing_table"]?.active != 'online' ? "text-muted" : "")}`}></i> {item[0]["existing_table"]?.active}</span>
                        {/* <p className="text">{item[0]["existing"]?.title}</p> */}
                    </div>
                    <div className="avatar-group">
                        <small className="group-status">{moment(item[0]["existing_table"]?.created_at).format('llll')}</small>
                        {/* <small className="group-status">13.07.2024</small> */}
                        {/* <div className="avatar">
                            <img src="assets/img/account/14.jpg" alt="" />
                        </div>
                        <div className="avatar">
                            <img src="assets/img/account/11.jpg" alt="" />
                        </div>
                        <div className="avatar">
                            <img src="assets/img/account/18.jpg" alt="" />
                        </div>
                        <div className="avatar">
                            <span className="avatar-text">+5</span>
                        </div> */}
                    </div>
                </div>
                {/* <p className="text" style={{paddingTop: "10px"}}>{item[0]["existing"]?.title}</p>
                <p className="text" style={{fontSize: "13px",paddinBottom: "10px"}}>{item[0]["existing"]?.description}</p> */}
            </div>
        </>
}