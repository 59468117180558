import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaneContacts from '../modules/Pane/PaneContacts'; 
// import ChatMessages from '../modules/Chats/components/ChatMessages'; 
import ProfileMore from '../modules/Profile/ProfileMore'; 
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { ProfileChatStart, ProfileChatHeader } from '../components/Profile/Profile'; 
import {selectUser} from '../modules/User/model/selectors/selectUser'
import {selectContacts} from '../modules/Contacts/model/selectors/selectContacts'
import {selectContactsLength} from '../modules/Contacts/model/selectors/selectContactsLength'
import { contactsList } from '../modules/Contacts/model/action/list'
import { contactsLength } from '../modules/Contacts/model/action/length'

export default function Contacts() {

    const dispatch = useDispatch(); // Получаем функцию dispatch из Redux
    let authorized = 0
    let user_id = 0
    let username = 0
  
    let cookie_split = document.cookie.split(';');
    for (let i = 0; i < cookie_split.length; i++) {
      let cookie_name_split = cookie_split[i].split('=');  
      if(cookie_name_split[0].replace(/\s+/g, '') === "authorized"){
        authorized = cookie_name_split[1]
      }
      if(cookie_name_split[0].replace(/\s+/g, '') === "user_id"){
        user_id = cookie_name_split[1]
      }
      if(cookie_name_split[0].replace(/\s+/g, '') === "username"){
        username = cookie_name_split[1]
      }
    }
    const contacts_list = useSelector(selectContacts)
    const contacts_length = useSelector(selectContactsLength)

    const [loadProfileChatStart, setProfileChatStart] = useState(null);
    const [loadProfileInfo, setProfileInfo] = useState(false);
    const [loadProfileData, setProfileData] = useState(0);

    const handleProfileChatStart = (value) => {
        setProfileChatStart(value);
        setProfileData(value);
    }

    const handleProfileInfo = (value) => {
        setProfileInfo(value);
    }

    useEffect(() => {
        const data = {
            user_id: user_id
        };
        dispatch(contactsList(data))
        dispatch(contactsLength(data))
    }, [dispatch, user_id]);

    return (
        <>
        <div className="side-menu-tab">
            <div className="tab-content">
                {contacts_list === null ? 
                    <>
                        <Stack spacing={2}>
                            <Skeleton variant="rounded" width={"100%"} height={24} />
                            <Skeleton variant="rounded" width={"100%"} height={56} />
                        </Stack>
                        <Stack spacing={2} sx={{mt: 3}}>
                            {Array(3).fill(null).map((_, index) => (
                                <Skeleton key={index} variant="rounded" width={"100%"} animation="wave" height={74} />
                            ))}
                        </Stack>
                    </>
                :
                    <PaneContacts 
                        title='Контакты'
                        placeholder='Поиск ...'
                        length={contacts_length}
                        list={contacts_list}
                        loadProfileChatStart={loadProfileChatStart}
                        onClickHandlerChangeProfileChat={(value) => handleProfileChatStart(value)}
                    />
                }
            </div>
        </div>
        {user_id !== null &&
            <>
                <div className={"layout-content" + (loadProfileChatStart !== null ? ' active' : '')}>
                    {loadProfileChatStart === null ? 
                    <ProfileChatStart user_id={user_id} username={username}/> :
                    <div className="chat-box">
                        <div className="container">
                        <div className="chat-wrap">
                            <ProfileChatHeader 
                                profile={contacts_list[loadProfileData]}
                                loadProfileInfo={loadProfileInfo}
                                onClickHandlerChangeProfileInfo={(value) => handleProfileInfo(value)}
                                onClickHandlerChangeProfileChat={(value) => handleProfileChatStart(value)}
                            />
                            <div className="scrollbar-wrap bottom-scroll">
                                <div className="chat-content">
                                    {/* <ChatMessages /> */}
                                </div>
                            </div>
                            {/* <div className="chat-bottom">
                                <div className="chat-form">
                                    <form action="#">
                                    <div className="chat-form-wrap">
                                        <div className="microphone">
                                            <button type="button"><i className="feather-mic"></i></button>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Введите сообщение..." />
                                        </div>
                                        <div className="emoji">
                                            <button type="button"><i className="feather-smile"></i></button>
                                        </div>
                                        <div className="chat-file">
                                            <button type="button" className="file-btn"><i className="feather-paperclip"></i></button>
                                            <input type="file" className="file-input" />
                                        </div>
                                        <div className="send-btn">
                                            <button type="submit"><i className="feather-send"></i></button>
                                        </div>
                                    </div>
                                    </form>
                                </div>
                            </div> */}
                        </div>
                        </div>
                    </div>}
                </div>
                <div className={"chat-user-more offcanvas offcanvas-end" + (loadProfileInfo ? ' show' : '')} data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="chatUserMore" aria-labelledby="chatUserMoreLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="chatUserMoreLabel">Информация</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>handleProfileInfo(!loadProfileInfo)}><i className="far fa-xmark"></i></button>
                    </div>
                    <div className="offcanvas-body scrollbar-wrap">
                        <div className="chat-user-info">
                            {contacts_list !== null &&
                            <ProfileMore 
                                profile={contacts_list[loadProfileData]}
                                loadProfileInfo={loadProfileInfo}
                                onClickHandlerChangeProfileInfo={(value) => handleProfileInfo(value)}
                            />}
                        </div>
                    </div>
                </div>
            </>
        }
    </>
    )
}