import {createAsyncThunk} from '@reduxjs/toolkit'
import {messagesDialogsAPI} from '../../api/messages'


export const dialogsMessages = createAsyncThunk('DIALOGS/MESSAGES', async args => {
	let res = await messagesDialogsAPI(args)
	return res
})



