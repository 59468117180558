

export default function ProfileMore(props) {

    const { profile, photo } = props;

    return (
        <>
            <div className="profile-content">
                <div className="profile-top">
                    <div className="avatar">
                        {profile?.photo || photo ? 
                            <img src={profile?.photo || photo} alt="" /> 
                            : 
                            <span className="avatar-text"><i className="feather-user"></i></span>
                        }
                        {/* <img src="assets/img/account/02.jpg" alt="" /> */}
                        {/* <span className="avatar-text"><i className="feather-user"></i></span> */}
                        <span className={"status online"}></span>
                    </div>
                    <h5>{profile?.first_name} {profile?.last_name}</h5>
                    <p style={{padding: "0 15px"}}>{profile?.about}</p>
                </div>
                <div className="profile-wrap">
                    <div className="label">
                        Профиль
                    </div>
                    <div className="profile-info">
                        <ul>
                            <li className="item">
                                <div className="info">
                                    <h6>Имя</h6>
                                    <p>{profile?.first_name} {profile?.name}</p>
                                </div>
                                <div className="icon">
                                    <i className="feather-user"></i>
                                </div>
                            </li>
                            <li className="item">
                                <div className="info">
                                    <h6>Фамилия</h6>
                                    <p>{(!profile?.last_name ? 'Не указано' : profile?.last_name)}</p>
                                </div>
                                <div className="icon">
                                    <i className="feather-user"></i>
                                </div>
                            </li>
                            <li className="item">
                                <div className="info">
                                    <h6>Имя пользователя</h6>
                                    <p>{(!profile?.username ? 'Не указано' : profile?.username)}</p>
                                </div>
                                <div className="icon">
                                    <i className="feather-mail"></i>
                                </div>
                            </li>
                            {/* <li className="item">
                                <div className="info">
                                    <h6>Телефон</h6>
                                    <p>(+2) 123 456 7889</p>
                                </div>
                                <div className="icon">
                                    <i className="feather-phone-call"></i>
                                </div>
                            </li> */}
                            {/* <li className="item">
                                <div className="info">
                                    <h6>Локация</h6>
                                    <p>Parkway Drive, USA</p>
                                </div>
                                <div className="icon">
                                    <i className="feather-globe"></i>
                                </div>
                            </li> */}
                            {/* <li className="item">
                                <div className="info">
                                    <h6>Дата создания</h6>
                                    <p>Aug 21, 2024</p>
                                </div>
                                <div className="icon">
                                    <i className="feather-calendar"></i>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                    <div className="label">
                        Дополнительно
                    </div>
                    <div className="profile-info">
                        <ul>
                            <li className="item">
                                <div className="info">
                                    <h6>Идентификатор</h6>
                                    <p>{profile?.id}</p>
                                </div>
                                <div className="icon">
                                    <i className="feather-user"></i>
                                </div>
                            </li>
                            {/* <li className="item">
                                <div className="info">
                                    <h6>Аккаунт бот</h6>
                                    <p>{(!profile?.bot ? 'Нет' : profile?.bot)}</p>
                                </div>
                                <div className="icon">
                                    <i className="feather-user"></i>
                                </div>
                            </li>
                            <li className="item">
                                <div className="info">
                                    <h6>Аккаунт удален</h6>
                                    <p>{(!profile?.deleted ? 'Нет' : profile?.deleted)}</p>
                                </div>
                                <div className="icon">
                                    <i className="feather-user"></i>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                    {/* <div className="label">
                        Share Photos & Files
                    </div>
                    <div className="chat-user-file">
                        <div className="chat-user-item">
                        <div className="accordion accordion-flush" id="chatUserFile">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <a href="#" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#chatuser-collapse1" aria-expanded="false" aria-controls="chatuser-collapse1">
                                    <div>
                                        <h6>Share Photo Gallery</h6>
                                        <p>Both your share photos</p>
                                    </div>
                                    </a>
                                </h2>
                                <div id="chatuser-collapse1" className="accordion-collapse collapse" data-bs-parent="#chatUserFile">
                                    <div className="accordion-body">
                                    <div className="chat-user-photo">
                                        <div className="message-img popup-gallery">
                                            <div className="img-item">
                                                <img src="assets/img/message/01.jpg" alt="" />
                                                <div className="overlay">
                                                <a className="popup-img" href="assets/img/message/01.jpg" title="Demo 01"><i className="feather-eye"></i></a>
                                                <a className="download" href="#"><i className="feather-download"></i></a>
                                                </div>
                                            </div>
                                            <div className="img-item">
                                                <img src="assets/img/message/02.jpg" alt="" />
                                                <div className="overlay">
                                                <a className="popup-img" href="assets/img/message/02.jpg" title="Demo 02"><i className="feather-eye"></i></a>
                                                <a className="download" href="#"><i className="feather-download"></i></a>
                                                </div>
                                            </div>
                                            <div className="img-item">
                                                <img src="assets/img/message/03.jpg" alt="" />
                                                <div className="overlay">
                                                <a className="popup-img" href="assets/img/message/03.jpg" title="Demo 03"><i className="feather-eye"></i></a>
                                                <a className="download" href="#"><i className="feather-download"></i></a>
                                                </div>
                                            </div>
                                            <div className="img-item">
                                                <img src="assets/img/message/04.jpg" alt="" />
                                                <div className="overlay">
                                                <a className="popup-img" href="assets/img/message/04.jpg" title="Demo 04"><i className="feather-eye"></i></a>
                                                <a className="download" href="#"><i className="feather-download"></i></a>
                                                </div>
                                            </div>
                                            <div className="img-item">
                                                <img src="assets/img/message/05.jpg" alt="" />
                                                <div className="overlay">
                                                <a className="popup-img" href="assets/img/message/05.jpg" title="Demo 05"><i className="feather-eye"></i></a>
                                                <a className="download" href="#"><i className="feather-download"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <a href="#" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#chatuser-collapse2" aria-expanded="false" aria-controls="chatuser-collapse2">
                                    <div>
                                        <h6>Share All Files</h6>
                                        <p>Both your share all media & files</p>
                                    </div>
                                    </a>
                                </h2>
                                <div id="chatuser-collapse2" className="accordion-collapse collapse" data-bs-parent="#chatUserFile">
                                    <div className="accordion-body">
                                    <div className="chat-user-file">
                                        <ul className="file-list">
                                            <li className="file-item">
                                                <div className="avatar">
                                                <img src="assets/img/message/01.jpg" alt="" />
                                                </div>
                                                <div className="file-info">
                                                <h6>image-1.jpg</h6>
                                                <span>3.25 MB image file</span>
                                                </div>
                                                <div className="file-action">
                                                <div className="dropdown">
                                                    <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="feather-more-vertical"></i>
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                        <li><a className="dropdown-item" href="#"><i className="feather-download"></i>Download</a></li>
                                                        <li><a className="dropdown-item" href="#"><i className="feather-share-2"></i>Share</a></li>
                                                        <li>
                                                            <hr className="dropdown-divider" />
                                                        </li>
                                                        <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                                    </ul>
                                                </div>
                                                </div>
                                            </li>
                                            <li className="file-item">
                                                <div className="avatar">
                                                <span className="avatar-text"><i className="far fa-file-zipper"></i></span>
                                                </div>
                                                <div className="file-info">
                                                <h6>example.zip</h6>
                                                <span>10.25 MB zip file</span>
                                                </div>
                                                <div className="file-action">
                                                <div className="dropdown">
                                                    <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="feather-more-vertical"></i>
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                        <li><a className="dropdown-item" href="#"><i className="feather-download"></i>Download</a></li>
                                                        <li><a className="dropdown-item" href="#"><i className="feather-share-2"></i>Share</a></li>
                                                        <li>
                                                            <hr className="dropdown-divider" />
                                                        </li>
                                                        <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                                    </ul>
                                                </div>
                                                </div>
                                            </li>
                                            <li className="file-item">
                                                <div className="avatar">
                                                <span className="avatar-text theme-bg-primary"><i className="far fa-video"></i></span>
                                                </div>
                                                <div className="file-info">
                                                <h6>video-1.mp4</h6>
                                                <span>20.50 MB video file</span>
                                                </div>
                                                <div className="file-action">
                                                <div className="dropdown">
                                                    <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="feather-more-vertical"></i>
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                        <li><a className="dropdown-item" href="#"><i className="feather-download"></i>Download</a></li>
                                                        <li><a className="dropdown-item" href="#"><i className="feather-share-2"></i>Share</a></li>
                                                        <li>
                                                            <hr className="dropdown-divider" />
                                                        </li>
                                                        <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                                    </ul>
                                                </div>
                                                </div>
                                            </li>
                                            <li className="file-item">
                                                <div className="avatar">
                                                <span className="avatar-text theme-bg-danger"><i className="far fa-music"></i></span>
                                                </div>
                                                <div className="file-info">
                                                <h6>audio-1.mp3</h6>
                                                <span>6.25 MB audio file</span>
                                                </div>
                                                <div className="file-action">
                                                <div className="dropdown">
                                                    <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="feather-more-vertical"></i>
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                        <li><a className="dropdown-item" href="#"><i className="feather-download"></i>Download</a></li>
                                                        <li><a className="dropdown-item" href="#"><i className="feather-share-2"></i>Share</a></li>
                                                        <li>
                                                            <hr className="dropdown-divider" />
                                                        </li>
                                                        <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                                    </ul>
                                                </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div> */}
                </div>
        </div>
    </>)}