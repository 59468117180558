import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import moment from "moment"
// import 'moment/locale/ru';
// import CardContacts from '../../../components/Card/CardContacts';

export default function integrationApps(props) {

    const { item, onClickhandleAccordionCollapse, accordion } = props;

    const onClickhandleModal = (titleContent, descriptionContent, name) => {
        // Обращаемся к элементу по селектору
        const title = document.querySelector('.integration-invite-title');
        const description = document.querySelector('.integration-invite-description');
        const component_name = document.querySelector('.integration-invite-component_name');

        title.innerHTML = titleContent;
        description.innerHTML = descriptionContent;
        component_name.innerHTML = name;

        const modal = document.querySelector('.integration-invite-modal');
        const backdrop = document.querySelector('.modal-backdrop');

        if (modal) {
            // Переключаем класс 'show'
            modal.classList.toggle('show');
            if (modal.style.display !== "none") {
                modal.style.display = "none";
            } else {
                modal.style.display = "block";
            }
        }
        if (backdrop) {
            // Переключаем класс 'show'
            backdrop.classList.toggle('show');
            if (backdrop.style.display !== "none") {
                backdrop.style.display = "none";
            } else {
                backdrop.style.display = "block";
            }
        }
    }

    return <>
            <div className="accordion-item py-1 my-1">
                <div className="accordion-header cursor-pointer" onClick={()=>onClickhandleAccordionCollapse("group-collapse"+item?.id)}>
                    <span className={"accordion-button "+("group-collapse"+item?.id === accordion ? "" : "collapsed")} data-bs-toggle="collapse" data-bs-target={"#group-collapse"+item?.id} aria-expanded={("group-collapse"+item?.id === accordion ? "true" : "false")} aria-controls={"group-collapse"+item?.id}>
                        <div style={{paddingRight: "15px"}}>
                            {/* <p className="text mb-1">{item?.application}</p> */}
                            <h6 className="title mb-2">{item?.title}</h6>
                            <p className="text" style={{fontSize: "15px"}}>{item?.description}</p>
                        </div>
                    </span>
                </div>
                <div id={"group-collapse"+item?.id} className={"accordion-collapse collapse "+("group-collapse"+item?.id === accordion ? "show" : "")} data-bs-parent="#accordion-group">
                    <div className="accordion-body">
                        <div className="group-form">
                            <form action="#">
                                {/* <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Group name" />
                                    <span className="input-group-text"></span>
                                </div> */}
                                <button type="button" className="theme-btn w-100" onClick={()=>onClickhandleModal(item?.title, item?.description, item?.component_name)}>Подключить</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
}