import {post} from '../../../api/post'


export const registerUserAPI = async (data) => {
	try {
		const response = await post('/user/register/', data);
		return response
	} catch (error) {
		throw error;
	}
};
