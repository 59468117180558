

export function SideMenuSearch(props) {
    const { placeholder } = props;

    return <>
        <div className="side-menu-search">
            <form action="#" style={{width: "100%"}}>
                <div className="input-group">
                    <input type="text" className="form-control" placeholder={placeholder} />
                    <span className="input-group-text"><i className="feather-search"></i></span>
                </div>
            </form>
        </div>
    </>
}