import {createAsyncThunk} from '@reduxjs/toolkit'
import {lengthContactsAPI} from '../../api/length'


export const contactsLength = createAsyncThunk('CONTACTS/LENGTH', async args => {
	let res = await lengthContactsAPI(args)
	return res
})



