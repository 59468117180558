import {post} from '../../../api/post'


export const listIntegrationsAPI = async (data) => {
	try {
		const response = await post('/web/integrations/list/', data);
		if(response.status === 'success'){
			return response.response
		} else {
			return null
		}
	} catch (error) {
		throw error;
	}
};
