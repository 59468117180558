import React from 'react'
import {Provider, useSelector} from 'react-redux'
import {store} from './models/store'
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'
import './App.css'
import './assets/css/bootstrap.min.css'
import './assets/css/all-fontawesome.min.css'
import './assets/css/feather.min.css'
import './assets/css/style.css'
import getCookie from './models/cookie'

import SignIn from "./templates/SignIn"
import Dashboard from "./templates/Dashboard"

import Dialogs from './pages/Dialogs'
import Contacts from './pages/Contacts'
import Login from './pages/Login'
// import Instructions from './pages/Instructions'
import Integrations from './pages/Integrations'
import Settings from './pages/Settings';


// import {selectIsAuthenticated} from './modules/User/model/selectors/selectIsAuthenticated'

// import SignIn from "./templates/SignIn";
// import Techida from "./templates/Techida";
// import Home from './pages/Home';
// import Login from './pages/Login';
// import Register from './pages/Register';

// import Audience from './pages/Audience';
// import Catalog from './pages/Catalog';

export default function App() {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}

const AppRouter = () => {

  let authorized = 0
  let user_id = 0
  let username = 0

  let cookie_split = document.cookie.split(';');
  
  for (let i = 0; i < cookie_split.length; i++) {
    let cookie_name_split = cookie_split[i].split('=');  
    if(cookie_name_split[0].replace(/\s+/g, '') === "authorized"){
      authorized = cookie_name_split[1]
    }
    if(cookie_name_split[0].replace(/\s+/g, '') === "user_id"){
      user_id = cookie_name_split[1]
    }
    if(cookie_name_split[0].replace(/\s+/g, '') === "username"){
      username = cookie_name_split[1]
    }
  }
  // console.log(authorized)
  return <BrowserRouter>
    <Routes>
      <Route path="/login" element={<SignIn />}>
        <Route index element={<Login />} />
      </Route>
      <Route
          path="*"
          element={
            authorized == 1 ? (
              <Dashboard />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        >
        <Route path="dialogs" element={<Dialogs />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="integrations" element={<Integrations />} />
      </Route>
    </Routes>
  </BrowserRouter>
}
