import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import { integrationsCreate } from '../../modules/Integrations/model/action/create'

export function ModalTelegramAuth(props) {
    
    const dispatch = useDispatch(); // Получаем функцию dispatch из Redux
    const [activeStep, setActiveStep] = useState(0);
    const [user_phone, setUserPhone] = useState('');
    const [api_id, setApiId] = useState('');
    const [api_hash, setApiHash] = useState('');
    const [integration_id, setIntegrationId] = useState('');
    const [session_string, setSessionString] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(null);

    let authorized = 0
    let user_id = 0
    let username = 0
  
    let cookie_split = document.cookie.split(';');
    for (let i = 0; i < cookie_split.length; i++) {
      let cookie_name_split = cookie_split[i].split('=');  
      if(cookie_name_split[0].replace(/\s+/g, '') === "authorized"){
        authorized = cookie_name_split[1]
      }
      if(cookie_name_split[0].replace(/\s+/g, '') === "user_id"){
        user_id = cookie_name_split[1]
      }
      if(cookie_name_split[0].replace(/\s+/g, '') === "username"){
        username = cookie_name_split[1]
      }
    }
    // const [phone_number, setPhoneNumber] = useState('');
    // console.log(user_phone)
    // console.log(props)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };     

    const handleNext = () => {
        const component_name = document.querySelector('.integration-invite-component_name');
        let session_name = component_name.innerText;

        if(user_phone !== '' && api_id !== '' && api_hash !== '' && session_string !== '' && integration_id !== ''){
            const data = {
                user_id: user_id,
                phone_number: user_phone,
                api_id: api_id,
                api_hash: api_hash,
                session_string: session_string,
                integration_id: integration_id,
                session_name: session_name,
                compose: 0
            };
            dispatch(integrationsCreate(data));
        }

        // console.log(activeStep)
        if(user_phone === '' && activeStep === 0){
            setMessage("Введите номер телефона");
            setOpen(true);
        } else if((api_id === '' || api_hash === '') && activeStep === 1){
            setMessage("Введите конфигурацию приложения");
            setOpen(true);
        } else if((session_string === '' || integration_id === '') && activeStep === 2){
            setMessage("Введите конфигурацию аккаунта");
            setOpen(true);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    // const steps = [
    //     {
    //         label: 'Номер телефона',
    //         description:'Это запустит интерактивную оболочку, предлагающую ввести ваш номер телефона , включая код страны (символы «плюс» +и «минус» -можно опустить).',
    //         component: <div className="contact-invite-form">
    //                         <form action="#">
    //                             <div className="input-group">
    //                                 <input type="text" className="form-control" placeholder="Телефон" name="user_phone" value={user_phone} onChange={(e) => setUserPhone(e.target.value)}/>
    //                                 <span className="input-group-text"><i className="feather-phone"></i></span>
    //                             </div>                                
    //                         </form>
    //                     </div> 
    //     },
    //     {
    //         label: 'Верификация приложения',
    //         description: `Для использования API Telegram требуется код телефона подтверждения, который вы получите на своих уже авторизованных устройствах или по SMS`,
    //         component: <div className="contact-invite-form">
    //                 <form action="#">
    //                     <div className="input-group">
    //                         <input type="text" className="form-control" placeholder="Код подтверждения" name="user_code" value={user_code} onChange={(e) => setUserCode(e.target.value)}/>
    //                         <span className="input-group-text"><i className="feather-key"></i></span>
    //                     </div>                                
    //                 </form>
    //             </div> 
    //     },
    //     {
    //         label: 'Верификация аккаунта',
    //         description: `Для использования API Telegram требуется код телефона подтверждения, который вы получите на своих уже авторизованных устройствах или по SMS`,
    //         component: <div className="contact-invite-form">
    //                 <form action="#">
    //                     <div className="input-group">
    //                         <input type="text" className="form-control" placeholder="Код подтверждения"/>
    //                         <span className="input-group-text"><i className="feather-key"></i></span>
    //                     </div>                                
    //                 </form>
    //             </div> 
    //     },
    // ];

    const steps = [
        {
            label: 'Номер телефона',
            description:'Это запустит интерактивную оболочку, предлагающую ввести ваш номер телефона , включая код страны (символы «плюс» +и «минус» -можно опустить).',
            component: <div className="contact-invite-form">
                            <form action="#">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Телефон" name="user_phone" value={user_phone} onChange={(e) => setUserPhone(e.target.value)}/>
                                    <span className="input-group-text"><i className="feather-phone"></i></span>
                                </div>                                
                            </form>
                        </div> 
        },
        {
            label: 'Конфигурация приложения',
            description: `Войдите здесь, чтобы управлять своими приложениями с помощью Telegram API`,
            component: <div className="contact-invite-form">
                    <div className="mb-4">
                        <a href='https://my.telegram.org/auth?to=apps' target="_blank">Как получить идентификатор приложения?</a>
                    </div>
                    <form action="#">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="App id" name="api_id" value={api_id} onChange={(e) => setApiId(e.target.value)}/>
                            
                            <span className="input-group-text"><i className="feather-key"></i></span>
                        </div>     
                        <div className="input-group">
                            
                            <input type="text" className="form-control" placeholder="App hash" name="api_hash" value={api_hash} onChange={(e) => setApiHash(e.target.value)}/>
                            <span className="input-group-text"><i className="feather-key"></i></span>
                        </div>                             
                    </form>
                </div> 
        },
        {
            label: 'Авторизация аккаунта',
            description: ``,
            component: <div className="contact-invite-form">
                    <form action="#">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Идентификатор аккаунта" name="integration_id" value={integration_id} onChange={(e) => setIntegrationId(e.target.value)}/>
                            
                            <span className="input-group-text"><i className="feather-key"></i></span>
                        </div>    
                        <div className="input-group">
                            
                            <textarea rows="4" className="form-control" placeholder="Сессия авторизации" name="session_string" value={session_string} onChange={(e) => setSessionString(e.target.value)}/>
                            <span className="input-group-text"><i className="feather-key"></i></span>
                        </div>                             
                    </form>
                </div> 
        },
    ];

    const onClickhandleModal = () => {
        // Обращаемся к элементу по селектору
        const modal = document.querySelector('.integration-invite-modal');
        const backdrop = document.querySelector('.modal-backdrop');

        if (modal) {
            // Переключаем класс 'show'
            modal.classList.toggle('show');
            modal.style.display = "none";  
        }
        if (backdrop) {
            // Переключаем класс 'show'
            backdrop.classList.toggle('show');
            backdrop.style.display = "none";                
        }
        setUserPhone('')
        setApiId('')
        setApiHash('')
        setIntegrationId('')
        setSessionString('')
        handleReset()
    }

    return <>
        <Snackbar
            anchorOrigin={{ 
                vertical: 'top',
                horizontal: 'center' 
            }}
            severity="info"
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={message}
        />
        <div className="modal integration-invite-modal fade" id="integrationInvite" tabIndex="-1" aria-hidden="true" style={{display: "none"}}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>onClickhandleModal()}><i className="feather-x"></i></button>
                    <div className="modal-body">
                        <div className="contact-invite-info">
                            <div className="contact-invite-header">
                                {/* <div className="avatar">
                                    <span className="avatar-text"><i className="fal fa-users"></i></span>
                                </div> */}
                                <h5 className="integration-invite-title"></h5>
                                <p className="integration-invite-description"></p>
                                <input type="hidden" name="session_name" id="session_name" className="integration-invite-component_name"/>
                            </div>
                            {/* <div className="contact-invite-form">
                                <form action="#">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Email"/>
                                        <span className="input-group-text"><i className="feather-mail"></i></span>
                                    </div>
                                    <div className="input-group">
                                        <textarea className="form-control" cols="30" rows="4" placeholder="Invite message"></textarea>
                                    </div>
                                    
                                    <div className="text-center">
                                        <button type="submit" className="theme-btn">Send Invite Request<i className="fas fa-angle-right"></i></button>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel
                                    // optional={
                                    //     index === 2 ? (
                                    //     <Typography variant="caption"></Typography>
                                    //     ) : null
                                    // }
                                >
                                    {step.label}
                                </StepLabel>
                                <StepContent>
                                <Typography>{step.description}</Typography>
                                <Box sx={{ mb: 1, mt: 2 }}>{step.component}</Box>
                                <Box sx={{ mb: 1, mt: 2 }}>
                                    <div>
                                        <button type="button" onClick={handleNext} className="theme-btn">{index === steps.length - 1 ? 'Подтвердить' : 'Продолжить'}</button>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 2, ml: 2 }}
                                        >
                                            Назад
                                        </Button>
                                    </div>
                                </Box>
                                </StepContent>
                            </Step>
                            ))}
                        </Stepper>

                        {activeStep === steps.length && (
                            <Paper square elevation={0} sx={{ p: 3 }}>
                                <Typography>Все шаги выполнены – идет установка, ожидайте ...</Typography>
                                <Box className="auth-btn mt-3">
                                    <button type="button" onClick={onClickhandleModal} className="theme-btn">{'Готово'}</button>
                                </Box>
                            </Paper>
                        )}

                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop fade" style={{display: "none"}} onClick={()=>onClickhandleModal()}></div>
    </>
}

// {length && length != 0 && <span className="badge rounded-pill">{length}</span>}{length}

