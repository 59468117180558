import {createSlice} from '@reduxjs/toolkit'
import {swipeableList} from './action/list'

const initialState = {
	list: null,
	loading: false,
}

const slice = createSlice({
	name: 'swipeable',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(swipeableList.pending, (state) => {
			state.loading = true
		})
		builder.addCase(swipeableList.fulfilled, (state, action) => {
			state.list = action.payload
			state.loading = false
		})
	}
})

export const {actions : swipeableActions} = slice
export const {reducer : swipeableReducer} = slice
