import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";

export default function CardDialogs(props) {
    
    const { item, onClickHandlerChangeProfileChat, idx } = props;
    // console.log(item[0])

    return <>
        <div className="chat-list-item cursor-pointer" onClick={()=>onClickHandlerChangeProfileChat(idx)}>
            <div className="chat-list-content">
                <div className="avatar">
                    {item[0]?.photo ? 
                        <img src={item[0]?.photo} alt="" /> 
                        : 
                        <span className="avatar-text"><i className="feather-user"></i></span>
                    }
                    <span className="status online"></span>
                </div>
                <div className="chat-list-info">
                    <div className="chat-list-title">
                        <h5 className="name">{item[0]?.first_name} {item[0]?.last_name}</h5>
                    </div>
                    <div className="chat-list-message" style={{
                        paddingBottom: "10px"
                    }}>
                        <p className="message-text">{item[0]["existing"]?.last_content}</p>
                        {/* <span className="message-count"></span> */}
                    </div>
                    {/* <div className="time small" style={{
                        textAlign: "right"
                    }}>{moment(item[0]?.last_created_at).format('llll')}</div> */}
                </div>
            </div>
        </div>
    </>
}