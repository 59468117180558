import {createSlice} from '@reduxjs/toolkit'
import {registerUser} from './action/register'
import {loginUser} from './action/login'

const initialState = {
	loading: false
}

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {
	},
	extraReducers: builder => {
		builder.addCase(registerUser.pending, (state) => {
			state.loading = true
		})
		builder.addCase(registerUser.rejected, (state) => {
			state.loading = false
		})
		builder.addCase(loginUser.pending, (state) => {
			state.loading = true
		})
		builder.addCase(loginUser.fulfilled, (state, action) => {
			document.cookie = "authorized=1; path=/;"
    		document.cookie = "user_id="+action.payload.id+"; path=/;";
			document.cookie = "username="+action.payload.username+"; path=/;";
			state.loading = false
		})
	}
})

export const {actions : userActions} = slice
export const {reducer : userReducer} = slice
