import React, { useState, useEffect, useMemo } from 'react';

export default function InputText(props) {
    
    const { item, value } = props;

    return <>
        <p>{item?.title}</p>
        <div className="input-group mt-2 mb-4">
            <input type="text" className="form-control" name={item?.name} placeholder={item?.placeholder_field} defaultValue={(value || item?.value_field)} />
        </div>
    </>
}