import {post} from '../../../api/post'


export const listSwipeableAPI = async (data) => {
	try {
		const response = await post('/gpt4free/chat/completions/', data);
		if(response.status === 'success'){
			return response.response
		} else {
			return null
		}
	} catch (error) {
		throw error;
	}
};
