import {createAsyncThunk} from '@reduxjs/toolkit'
import {createIntegrationsAPI} from '../../api/create'


export const integrationsCreate = createAsyncThunk('INTEGRATIONS/CREATE', async args => {
	let res = await createIntegrationsAPI(args)
	return res
})



