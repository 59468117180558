import React, { useState, useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from "react-router-dom";
import { ModalTelegramAuth } from '../components/Modal/ModalTelegramAuth';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import {selectUserLoading} from '../modules/User/model/selectors/selectUserLoading'

import MobileSwipeableDrawer from '../modules/Swipeable/components/MobileSwipeableDrawer'
import {selectIntegrationsLoading} from '../modules/Integrations/model/selectors/selectIntegrationsLoading'
import {selectDialogsLoading} from '../modules/Dialogs/model/selectors/selectDialogsLoading'
import {selectContactsLoading} from '../modules/Contacts/model/selectors/selectContactsLoading'
import {selectSwipeableLoading} from '../modules/Swipeable/model/selectors/selectSwipeableLoading'


function Dashboard() {
    // // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    let cookie_drawer = 0;
    let cookie_split = document.cookie.split(';');
    for (let i = 0; i < cookie_split.length; i++) {
        let cookie_name_split = cookie_split[i].split('=');  
        if(cookie_name_split[0].replace(/\s+/g, '') === "drawer"){
            cookie_drawer = cookie_name_split[1];
        }
    }

    const location_pathname = window.location.pathname;
    const [pathname, setCurrentPage] = useState(location_pathname);
    const [currentSwipeableDrawer, handleSwipeableDrawer] = useState(null);
    
    const loadingUser = useSelector(selectUserLoading)
    const loadingIntegration = useSelector(selectIntegrationsLoading)
    const loadingDialogs = useSelector(selectDialogsLoading)
    const loadingContacts = useSelector(selectContactsLoading)
    const loadingSwipeable = useSelector(selectSwipeableLoading)

    // console.log(loadingUser, loadingIntegration, loadingDialogs, loadingContacts)
    
    const handleCurrentPage = (value) => {
        setCurrentPage(value);
    }

    const handleLogoutPage = () => {
        // event.preventDefault();
        document.cookie = "authorized=0; path=/;"
        document.cookie = "user_id=0; path=/;";
        document.cookie = "username=0; path=/;";
        document.cookie = "drawer=0; path=/;";
        window.location.replace('/login');
    }
    
    return (
        <>
            <main className="main-layout">
                
                <div className="layout-wrapper">
                    <div className="side-menu">
                        <div className="nav-brand">
                            <a className="logo" href="/"><img src="assets/img/logo/logo.png" alt="logo" /></a>
                        </div>
                        <ul className="nav flex-column nav-pills mb-3" role="tablist">
                            <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="Диалоги">
                                <Link to="/dialogs" className={(pathname === "/dialogs" ? "nav-link active" : "nav-link")} onClick={()=>handleCurrentPage("/dialogs")}>
                                    <i className="feather-message-circle icon"></i><span className="nav-active-shape"></span>
                                </Link>
                            </li>
                            <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="Контакты">
                                <Link to="/contacts" className={(pathname === "/contacts" ? "nav-link active" : "nav-link")} onClick={()=>handleCurrentPage("/contacts")}>
                                    <i className="feather-users icon"></i><span className="nav-active-shape"></span>
                                </Link>
                            </li>
                            <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="Интеграции">
                                <Link to="/integrations" className={(pathname === "/integrations" ? "nav-link active" : "nav-link")} onClick={()=>handleCurrentPage("/integrations")}>
                                    <i className="feather-rss icon"></i><span className="nav-active-shape"></span>
                                </Link>
                            </li>
                            {/* <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="Нейросети">
                                <Link to="/instructions" className={(pathname === "/instructions" ? "nav-link active" : "nav-link")} onClick={()=>handleCurrentPage("/instructions")}>
                                    <i className="feather-message-square icon"></i><span className="nav-active-shape"></span>
                                </Link>
                            </li> */}
                            {/* <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="Настройки">
                                <Link to="/settings" className={(pathname === "/settings" ? "nav-link active" : "nav-link")} onClick={()=>handleCurrentPage("/settings")}>
                                    <i className="feather-settings icon"></i><span className="nav-active-shape"></span>
                                </Link>
                            </li> */}
                            {/* <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="Аудитория">
                                <Link to="/audience" className={(pathname === "/audience" ? "nav-link active" : "nav-link")} onClick={()=>handleCurrentPage("/audience")}>
                                    <i className="feather-users icon"></i><span className="nav-active-shape"></span>
                                </Link>
                            </li> */}
                            {/* <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="Каталог">
                                <Link to="/catalog" className={(pathname === "/catalog" ? "nav-link active" : "nav-link")} onClick={()=>handleCurrentPage("/catalog")}>
                                    <i className="feather-shopping-bag icon"></i><span className="nav-active-shape"></span>
                                </Link>
                            </li> */}
                        </ul>
                        <div className="account">
                            <Link to="/login" className={"nav-link"} onClick={()=>handleLogoutPage()}>
                                <i className="feather-power icon"></i>
                            </Link>
                            {/* <button className="dropdown-item" onClick={()=>handleLogout()}><i className="feather-power"></i></button> */}
                            {/* <div className="color-mode theme-mode-control">
                            <a href="#" className="light-btn" data-bs-toggle="tooltip" data-bs-placement="right" title="Light"><i className="feather-sun"></i></a>
                            <a href="#" className="dark-btn" data-bs-toggle="tooltip" data-bs-placement="right" title="Dark"><i className="feather-moon"></i></a>
                            </div>
                            <div className="dropdown">
                            <a href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="avatar">
                                    <img src="assets/img/account/03.jpg" alt="" />
                                    <span className="status online"></span>
                                </div>
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="login.html"><i className="feather-log-in"></i>Login</a></li>
                                <li><a className="dropdown-item" href="register.html"><i className="feather-user-plus"></i>Register</a></li>
                                <li><a className="dropdown-item" href="forgot-password.html"><i className="feather-key"></i>Forgot Password</a></li>
                                <li><a className="dropdown-item" href="lock-screen.html"><i className="feather-lock"></i>Lock Screen</a></li>
                                <li>
                                    
                                    <hr className="dropdown-divider" />
                                   
                                </li>
                                <li><a className="dropdown-item" href="#"><i className="feather-log-out"></i>Logout</a></li>
                            </ul>
                            </div> */}
                        </div>
                    </div>
                    {/* An <Outlet> renders whatever child route is currently active,
                        so you can think about this <Outlet> as a placeholder for
                        the child routes we defined above. */}
                    <Outlet /> 
                    <ModalTelegramAuth />
                </div>
                {(loadingUser === true || loadingIntegration === true || loadingDialogs === true || loadingContacts === true || loadingSwipeable === true) && <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: "100%", zIndex: 9999 }}>
                    <LinearProgress />
                </Box>}
            </main>
            <Box className={'mobile-menu'}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    position: 'relative',
                    backgroundColor: '#fff',
                    borderRadius: '15px',
                    padding: '5px 12px',
                    fontSize: '28px',
                    border: '1px solid',
                    borderColor: 'var(--theme-bg2)',
                    justifyContent: 'space-evenly',
                    "& i": {
                        color: "var(--color-info-dark)"
                    },
                    "& a.active i": {
                        color: "#7603bd"
                    }
                }}>
                    <Link to="/dialogs" className={(pathname === "/dialogs" ? "nav-link active" : "nav-link")} onClick={()=>handleCurrentPage("/dialogs")}>
                        <i className="feather-message-circle icon"></i><span className="nav-active-shape"></span>
                    </Link>
                    <Link to="/contacts" className={(pathname === "/contacts" ? "nav-link active" : "nav-link")} onClick={()=>handleCurrentPage("/contacts")}>
                        <i className="feather-users icon"></i><span className="nav-active-shape"></span>
                    </Link>
                    <Link to="/integrations" className={(pathname === "/integrations" ? "nav-link active" : "nav-link")} onClick={()=>handleCurrentPage("/integrations")}>
                        <i className="feather-rss icon"></i><span className="nav-active-shape"></span>
                    </Link>
                    <i className="feather-message-square" onClick={()=>handleSwipeableDrawer("chat-ai")}></i>
                    <i className="feather-grid" onClick={()=>handleSwipeableDrawer("grid")}></i>
                </Box>           
            </Box>
            <MobileSwipeableDrawer currentSwipeableDrawer={currentSwipeableDrawer} onClickHandlerSwipeableDrawer={(value) => handleSwipeableDrawer(value)} onHandleLogoutPage={()=>handleLogoutPage()}/>
        </>
    ); 
}

// Dashboard.propTypes = {
//     /**
//      * Injected by the documentation to work in an iframe.
//      * You won't need it on your project.
//      */
//     winow: PropTypes.func,
// };
  
export default Dashboard;