import React, { useState, useEffect, useMemo } from 'react';
import InputText from "./InputText"

export default function InputTextMulti(props) {
    
    const { item, value } = props;

    console.log(item)
    const chars = value.split(';');
    console.log(chars);

    return <>
        <p>{item?.title}</p>
        <div style={{
            display: "flex",
            overflowX: "auto",
            marginBottom: "30px"
        }}>
            {chars.map((item, index) => (
                <div className="input-group mt-2 mb-4" key={index} style={{minWidth: "80%", marginRight: "15px"}}>
                    <input type="text" className="form-control" name={item?.name} placeholder={item?.placeholder_field} defaultValue={(item || item?.value_field)} />
                    <i className="feather-trash-2 trash-form-multi"></i>
                </div>
                // <InputText key={index} item={item} value={item}/>
            ))}
        </div>
    </>
}