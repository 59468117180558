import React, { useState, useEffect, useMemo } from 'react';
import CardContacts from '../../../components/Card/CardContacts';

export default function ContactsList(props) {

    const { loadProfileChatStartChatList, onClickHandlerChangeProfileChatChatList, contacts_list } = props;

    return <div className="contact-list scrollbar-wrap mt-3">
                <div className="contact-list-wrap">
                    {contacts_list.map((item, index) => (
                        <CardContacts
                            key={index}
                            idx={index}
                            item={item}
                            loadProfileChatStartChatList={loadProfileChatStartChatList}
                            onClickHandlerChangeProfileChat={(value) => onClickHandlerChangeProfileChatChatList(value)}
                        />
                    ))}
                </div>
            </div>
}