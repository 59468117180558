import {configureStore} from "@reduxjs/toolkit"
import {userReducer} from '../modules/User/model/userSlice'
import {contactsReducer} from '../modules/Contacts/model/contactsSlice'
import {integrationsReducer} from '../modules/Integrations/model/integrationsSlice'
import {dialogsReducer} from '../modules/Dialogs/model/dialogsSlice'
import {swipeableReducer} from '../modules/Swipeable/model/swipeableSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    contacts: contactsReducer,
    integrations: integrationsReducer,
    dialogs: dialogsReducer,
    swipeable: swipeableReducer
  }
})

export default store
