import {createAsyncThunk} from '@reduxjs/toolkit'
import {listDialogsAPI} from '../../api/list'


export const dialogsList = createAsyncThunk('DIALOGS/LIST', async args => {
	let res = await listDialogsAPI(args)
	return res
})



