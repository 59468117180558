import React, { useState, useEffect, useMemo } from 'react';


export function SideMenuHeader(props) {
    const { title, length, icon } = props;

    return <>
        <div className="side-menu-header">
            <h2 className="title">
                {/* <i className={`icon-pages feather-${icon} rotating-icon`}></i>  */}
                {title} {length !== 0 ? <span className="badge rounded-pill">{length}</span> : ''}</h2>
        </div>
        {/* <span className='bb'><i className={`icon-pages feather-${icon} rotating-icon`}></i></span>  */}
        {/* <div className="bb"></div> */}
    </>
}

// {length && length != 0 && <span className="badge rounded-pill">{length}</span>}{length}