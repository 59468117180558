import {createAsyncThunk} from '@reduxjs/toolkit'
import {listIntegrationsAPI} from '../../api/list'


export const integrationsList = createAsyncThunk('INTEGRATIONS/LIST', async args => {
	let res = await listIntegrationsAPI(args)
	return res
})



