import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {selectSwipeable} from '../model/selectors/selectSwipeable'
import { swipeableList } from '../model/action/list'
import DialogsMessages from '../../Dialogs/components/DialogsMessages'; 
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function ChatGrid(props) {

    const {onHandleLogoutPage} = props;


    return <div className="chat-drawer">
        <h5 className="title pt-3 mb-3" style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center"
        }}>
            {/* <i className="icon-pages feather-settings"></i> */}
            Сервисы
        </h5>
        <Stack spacing={3}>
            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
        </Stack>
        <h5 className="title mt-4 pt-3 mb-3" style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center"
        }}> 
            {/* <i className="icon-pages feather-lock"></i> */}
            Активные сеансы
        </h5>
        <div className="item">
            <div className="accordion accordion-flush">
                <div className="accordion-item accordion-text">
                    <div className="accordion-header">
                        <div className="accordion-button">
                        <div>
                            <h6>Windows - Sat 10:15 PM</h6>
                            <p>Chrome Browser - Parkway, USA</p>
                        </div>
                        <span className="danger" data-bs-toggle="tooltip" aria-label="Active Device" data-bs-original-title="Active Device"><i className="far fa-circle-dot"></i></span>
                        </div>
                    </div>
                </div>
                <div className="accordion-item accordion-text">
                    <div className="accordion-header">
                        <div className="accordion-button">
                        <div>
                            <h6>iPhone - Sun 12:15 PM</h6>
                            <p>Chrome Browser - Parkway, USA</p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item accordion-text">
                    <div className="accordion-header">
                        <div className="accordion-button">
                        <div>
                            <h6>Android - Fri 11:25 AM</h6>
                            <p>Chrome Browser - Parkway, USA</p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item accordion-text">
                    <div className="accordion-header">
                        <div className="accordion-button" onClick={()=>onHandleLogoutPage()}>
                            <div>
                                <h6>Выйти из профиля</h6>
                                <p>Sign out from all devices</p>
                            </div>
                            <div className="icon">
                                <i className="feather-log-out"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </div>
}
