import React, { useState, useEffect, useMemo } from 'react';

export default function Textarea(props) {
    
    const { item, value } = props;

    return <>
        <p>{item?.title}</p>
        <div className="input-group mt-2 mb-4">
            <textarea className="form-control" cols="30" rows="4" name={item?.name} placeholder={item?.placeholder_field}>{(value || item?.value_field)}</textarea>
        </div>
    </>
}