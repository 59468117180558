import React, { useState, useEffect, useMemo } from 'react';
import { SideMenuSearch } from '../../components/Search/Search';
import { SideMenuHeader } from '../../components/Header/Header';
import DialogsList from '../../modules/Dialogs/components/DialogsList'; 


export default function PaneDialogs(props) {

   const { loadProfileChatStart, onClickHandlerChangeProfileChat, list, title, placeholder, length } = props;

   return (
        <>
            <div className="tab-pane notification fade show active" id="pills-sm1" role="tabpanel" aria-labelledby="pills-sm-tab1" tabIndex="0">
               <div className="side-menu-content">
                  <SideMenuHeader title={title} length={length} icon={'message-circle'}/>
                  <SideMenuSearch placeholder={placeholder} />
                  <div className="sub-header">
                     <h5 className="sub-title">Недавние</h5>
                     <div className="dropdown">
                        <a href="#" data-bs-toggle="dropdown" aria-expanded="false"><i className="far fa-bars-filter"></i></a>
                        <ul className="dropdown-menu dropdown-menu-end">
                           <li><a className="dropdown-item" href="#"><i className="feather-calendar"></i>By Date</a></li>
                           <li><a className="dropdown-item" href="#"><i className="feather-users"></i>By Members</a></li>
                           <li><a className="dropdown-item" href="#"><i className="feather-cloud-snow"></i>By Groups</a></li>
                        </ul>
                     </div>
                  </div>
                  <DialogsList 
                     list={list}
                     loadProfileChatStartChatList={loadProfileChatStart}
                     onClickHandlerChangeProfileChatChatList={(value) => onClickHandlerChangeProfileChat(value)}
                  />
               </div>
            </div>
        </>
    )
}