import React, { useState, useEffect, useMemo } from 'react';


export default function CardChats(props) {
    
    const { idx, onClickHandlerChangeProfileChat, item } = props;

    // console.log(item)

    return <>
        <div className="item">
            <span className="avatar cursor-pointer" onClick={()=>onClickHandlerChangeProfileChat(idx)}>
                {item?.photo ? 
                    <img src={item?.photo} alt="" /> 
                    : 
                    <span className="avatar-text"><i className="feather-user"></i></span>
                }
                {/* <span className="avatar-text">{item?.first_name[0]}</span> */}
                <span className="status online"></span>
            </span>
            <div className="info">
                <h6 className="title cursor-pointer" onClick={()=>onClickHandlerChangeProfileChat(idx)}>{item?.first_name} {item?.last_name}</h6>
                <p className="text">{item?.username && <span style={{fontSize: "13px"}}>@{item?.username}</span>}</p>
            </div>
            <div className="action">
                <div className="dropdown">
                    <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>onClickHandlerChangeProfileChat(idx)}>
                        <i className="feather-more-vertical"></i>
                    </button>
                </div>
            </div>
        </div>
    </>
}