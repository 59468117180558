import axios from 'axios';

// const HOST = "http://localhost:8000"
const HOST = "https://idevbots.com/api"

/*
Обёртка для API, рядом в папке обёртки для get, patch, post, delete запросов, там можешь доработать обработку ошибок
*/
export const api = axios.create({
  baseURL: HOST,
  responseType: "json",
})

/*
Запрос ниже я поправил использовав обёртку post.
Также эти все запросы мне кажется наверняка относятся к бизнес сущностям твоего приложения, лучше их положить отдельно в папку сущности
этот запрос я перенёс как я вижу его в
modules/user/api/register.js
 */
// export const authRegisterApi = async (data) => {
//   try {
//     const response = await post('/user/register/', data);
//     return response
//   } catch (error) {
//     throw error;
//   }
// };

/*
modules/user/api/login.js (например)
 */
export const authLoginApi = async (data) => {
  try {
    const response = await axios.post(HOST + '/user/login/', data);
    return response.data
  } catch (error) {
    throw error;
  }
};

/*
хз что за запросы ниже но возможно это сущность integration, либо что-то обобщённое,
не обязательно что название сущности совпадает с моделькой на бэкенде
modules/integration/api/login.js (например)
а может это также относится к user сущности?
 */
export const integrationCreateApi = async (data, headers) => {
  try {
    const response = await axios.post(HOST + `/integration/application/create/`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const integrationListApi = async (data, headers) => {
  try {
    const response = await axios.post(HOST +  `/web/integration/items/`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const integrationTextGenerationApi = async (data, headers) => {
  try {
    const response = await axios.post(HOST +  `/llm/foundation-models/yandexgpt/text-generation/`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const integrationSyncApi = async (data, headers) => {
  try {
    const response = await axios.post(HOST +  `/integration/application/sync/`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const integrationUpdateApi = async (data, headers) => {
  try {
    const response = await axios.post(HOST +  `/integration/application/update/`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*
Инструкции?
modules/instruction/api/create.js (например)
 */

export const instructionsCreateApi = async (data, headers) => {
  try {
    const response = await axios.post(HOST + `/instructions/application/create/`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const instructionsListApi = async (data, headers) => {
  try {
    const response = await axios.post(HOST +  `/web/instructions/items/`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const instructionsUpdateApi = async (data, headers) => {
  try {
    const response = await axios.post(HOST + `/instructions/application/update/`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*
modules/chat/api/chatList.js (например)
 */

export const chatsListApi = async (data, headers) => {
  try {
    const response = await axios.post(HOST +  `/web/chats/items/`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const chatMessagesApi = async (data, headers) => {
  try {
    const response = await axios.post(HOST +  `/web/chat/messages/`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};
