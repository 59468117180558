import {createSlice} from '@reduxjs/toolkit'
import {contactsList} from './action/list'
import {contactsLength} from './action/length'

const initialState = {
	list: null,
	length: null,
	loading: false,
}

const slice = createSlice({
	name: 'contacts',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(contactsList.pending, (state) => {
			state.loading = true
			state.length = null
		})
		builder.addCase(contactsList.fulfilled, (state, action) => {
			state.list = action.payload
			state.loading = false
		})
		builder.addCase(contactsLength.pending, (state) => {
			state.loading = true
		})
		builder.addCase(contactsLength.fulfilled, (state, action) => {
			state.length = action.payload
			state.loading = false
		})
	}
})

export const {actions : contactsActions} = slice
export const {reducer : contactsReducer} = slice
