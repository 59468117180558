import React, { useState, useEffect, useMemo } from 'react';
import { SideMenuSearch } from '../../components/Search/Search';
import { SideMenuHeader } from '../../components/Header/Header';
import ContactsList from '../../modules/Contacts/components/ContactsList'; 

export default function PaneContacts(props) {

   const { loadProfileChatStart, onClickHandlerChangeProfileChat, list, title, placeholder, length } = props;

   return (
        <>
            <div className="tab-pane notification fade show active" id="pills-sm1" role="tabpanel" aria-labelledby="pills-sm-tab1" tabIndex="0">
               <div className="side-menu-content">
                  <SideMenuHeader title={title} length={length} icon={'users'}/>
                  <SideMenuSearch placeholder={placeholder} />
                  <ContactsList 
                     contacts_list={list}
                     loadProfileChatStartChatList={loadProfileChatStart}
                     onClickHandlerChangeProfileChatChatList={(value) => onClickHandlerChangeProfileChat(value)}
                  />
               </div>
            </div>
        </>
    )
}