import React, { useState, useEffect, useMemo } from 'react';

export function ProfileChatStart(props) {

    const { user_id, username } = props; 

    return <div className="chat-start">
        <div className="container">
            <div className="chat-start-info">
                <div className="avatar">
                    <img src="assets/img/account/08.jpg" alt="" />
                    <span className="status online"></span>
                </div>
                <h5>Привет! {username}</h5>
                <p>Выберите человека или группу, чтобы начать чат.</p>
                <div id="telegram-login"></div>
            </div>
        </div>
    </div>
}

export function ProfileChatHeader(props) {

    const { loadProfileInfo, onClickHandlerChangeProfileInfo, onClickHandlerChangeProfileChat, profile } = props;

    return <div className="chat-header">
        <div className="layout-content-btn">
            <button type="button" onClick={()=>onClickHandlerChangeProfileChat(null)}><i className="far fa-xmark"></i></button>
        </div>
        <span className="chat-user cursor-pointer" data-bs-toggle="offcanvas" data-bs-target="#chatUserMore" aria-controls="chatUserMore" onClick={()=>onClickHandlerChangeProfileInfo(!loadProfileInfo)}>
            <div className="avatar">
                {/* <img src="assets/img/account/02.jpg" alt="" /> */}
                {profile?.photo ? 
                    <img src={profile?.photo} alt="" /> 
                    : 
                    <span className="avatar-text"><i className="feather-user"></i></span>
                }
                <span className="status online"></span>
            </div>
            <div className="info">
                <h6 className="cursor-pointer" onClick={()=>onClickHandlerChangeProfileInfo(!loadProfileInfo)}>{profile?.first_name} {profile?.last_name}</h6>
                <span>{profile?.username && <span style={{fontSize: "13px"}}>@{profile?.username}</span>}</span>
            </div>
        </span>
        <div className="chat-action">
            <ul className="list">
                <li>
                    <div className="search-btn">
                        <button type="button" className="action-btn"><i className="feather-search"></i></button>
                        <div className="search-form">
                            <form action="#">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search Here..." />
                                <span className="input-group-text"><i className="feather-search"></i></span>
                            </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li className="user-btn"><button type="button" className="action-btn" data-bs-toggle="offcanvas" data-bs-target="#chatUserMore" aria-controls="chatUserMore" onClick={()=>onClickHandlerChangeProfileInfo(!loadProfileInfo)}><i className="feather-user"></i></button></li>
                <li className="audio-call"><button type="button" className="action-btn" data-bs-toggle="modal" data-bs-target="#audio-call"><i className="feather-phone"></i></button></li>
                <li className="video-call"><button type="button" className="action-btn" data-bs-toggle="modal" data-bs-target="#video-call"><i className="feather-video"></i></button></li>
                <li>
                    <div className="dropdown">
                        <button type="button" className="action-btn" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="feather-more-vertical"></i>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end" style={{
                            position: "absolute",
                            right: "20%"
                        }}>
                            <li><a className="dropdown-item" href="#" data-bs-toggle="offcanvas" data-bs-target="#chatUserMore" aria-controls="chatUserMore" onClick={()=>onClickHandlerChangeProfileInfo(!loadProfileInfo)}><i className="feather-user"></i>Информация</a></li>
                            <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#audio-call"><i className="feather-phone"></i>Audio Call</a></li>
                            <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#video-call"><i className="feather-video"></i>Video Call</a></li>
                            <li><a className="dropdown-item" href="#"><i className="feather-volume-x"></i>Mute Notifications</a></li>
                            <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete Chat</a></li>
                            <li>
                            <hr className="dropdown-divider" />
                            </li>
                            <li><a className="dropdown-item" href="#"><i className="feather-slash"></i>Block User</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
}

export function IntegrationChatHeader(props) {

    const { loadProfileInfo, onClickHandlerChangeProfileInfo, onClickHandlerChangeProfileChat, profile, photo } = props;

    // console.log(profile)
    return <div className="chat-header">
        <div className="layout-content-btn">
            <button type="button" onClick={()=>onClickHandlerChangeProfileChat(null)}><i className="far fa-xmark"></i></button>
        </div>
        <span className="chat-user cursor-pointer" data-bs-toggle="offcanvas" data-bs-target="#chatUserMore" aria-controls="chatUserMore" onClick={()=>onClickHandlerChangeProfileInfo(!loadProfileInfo)}>
            <div className="avatar">
                {/* <img src="assets/img/account/02.jpg" alt="" /> */}
                {photo ? 
                    <img src={photo} alt="" /> 
                    : 
                    <span className="avatar-text"><i className="feather-rss"></i></span>
                }
                <span className={"status "+profile?.active}></span>
            </div>
            <div className="info">
                <h6 className="cursor-pointer">{profile?.name}</h6>
                <span>{profile?.username && <a href={`https://t.me/${profile?.username}`} target="_blank" style={{fontSize: "13px"}}>@{profile?.username}</a>}</span>
            </div>
        </span>
        <div className="chat-action">
            <ul className="list">
                <li>
                    <div className="search-btn">
                        <button type="button" className="action-btn"><i className="feather-search"></i></button>
                        <div className="search-form">
                            <form action="#">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search Here..." />
                                <span className="input-group-text"><i className="feather-search"></i></span>
                            </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li className="user-btn"><button type="button" className="action-btn" data-bs-toggle="offcanvas" data-bs-target="#chatUserMore" aria-controls="chatUserMore" onClick={()=>onClickHandlerChangeProfileInfo(!loadProfileInfo)}><i className="feather-user"></i></button></li>
                <li className="audio-call"><button type="button" className="action-btn" data-bs-toggle="modal" data-bs-target="#audio-call"><i className="feather-phone"></i></button></li>
                <li className="video-call"><button type="button" className="action-btn" data-bs-toggle="modal" data-bs-target="#video-call"><i className="feather-video"></i></button></li>
                <li>
                    <div className="dropdown">
                        <button type="button" className="action-btn" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="feather-more-vertical"></i>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end" style={{
                            position: "absolute",
                            right: "20%"
                        }}>
                            <li><a className="dropdown-item" href="#" data-bs-toggle="offcanvas" data-bs-target="#chatUserMore" aria-controls="chatUserMore" onClick={()=>onClickHandlerChangeProfileInfo(!loadProfileInfo)}><i className="feather-user"></i>Информация</a></li>
                            <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#audio-call"><i className="feather-phone"></i>Audio Call</a></li>
                            <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#video-call"><i className="feather-video"></i>Video Call</a></li>
                            <li><a className="dropdown-item" href="#"><i className="feather-volume-x"></i>Mute Notifications</a></li>
                            <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete Chat</a></li>
                            <li>
                            <hr className="dropdown-divider" />
                            </li>
                            <li><a className="dropdown-item" href="#"><i className="feather-slash"></i>Block User</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
}
