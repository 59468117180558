import React, { useState, useEffect, useMemo } from 'react';
import Textarea from "./Textarea"

export default function TextareaMulti(props) {
    
    const { item, value } = props;

    console.log(item)
    const chars = value.split(';');
    console.log(chars);

    return <>
        <p>{item?.title}</p>
        <div style={{
            display: "flex",
            overflowX: "auto",
            marginBottom: "30px"
        }}>
            {chars.map((item, index) => (
                <div className="input-group mt-2 mb-4 mr-3" key={index} style={{minWidth: "80%", marginRight: "15px"}}>
                    <textarea className="form-control" cols="30" rows="6" name={item?.name} placeholder={item?.placeholder_field}>{(item || item?.value_field)}</textarea>
                    <i className="feather-trash-2 trash-form-multi"></i>
                </div>
                // <Textarea key={index} item={item} value={item}/>
            ))}
        </div>
    </>
}