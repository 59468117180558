import React, { useState } from 'react';
import { SideMenuSearch } from '../../components/Search/Search';
import { SideMenuHeader } from '../../components/Header/Header';
import IntegrationsList from '../../modules/Integrations/components/IntegrationsList'; 
import IntegrationsApps from '../../modules/Integrations/components/IntegrationsApps'; 

export default function PaneChatTabs(props) {

   const { title, placeholder, list, btn1, btn2, length, apps, onClickHandlerChangeProfileChat } = props;
   const [pillsTablist, setPillsTablist] = useState('pills-group1');
   const [accordion, setAccordionCollapse] = useState(null);

   const handleAccordionCollapse = (value) => {
      if(value === accordion){
         setAccordionCollapse(null);
      } else {
         setAccordionCollapse(value);
      }
      
   } 
   const handleProfileChatStart = (value) => {
      setPillsTablist(value);
   }

   return (
        <>
            <div className="tab-pane notification fade show active" id="pills-sm2" role="tabpanel" aria-labelledby="pills-sm-tab2" tabIndex="0">
               <div className="side-menu-content">
                  <SideMenuHeader title={title} length={length} icon={'rss'}/>
                  <SideMenuSearch placeholder={placeholder} />
                  <div className="group-menu-tab">
                     <ul className="nav nav-pills nav-justified mb-4" role="tablist">
                        <li className="nav-item">
                           <button className={"nav-link"  + (pillsTablist === 'pills-group1' ? ' active' : '')} id="pills-group-tab1" data-bs-toggle="pill" data-bs-target="#pills-group1" type="button" role="tab" aria-controls="pills-group1" aria-selected="true" onClick={()=>handleProfileChatStart('pills-group1')}>{btn1}</button>
                        </li>
                        <li className="nav-item">
                           <button className={"nav-link"  + (pillsTablist === 'pills-group2' ? ' active' : '')} id="pills-group-tab2" data-bs-toggle="pill" data-bs-target="#pills-group2" type="button" role="tab" aria-controls="pills-group2" aria-selected="false" onClick={()=>handleProfileChatStart('pills-group2')}>{btn2}</button>
                        </li>
                     </ul>
                  </div>
                  <div className="group-menu-content scrollbar-wrap">
                     <div className="tab-content">
                        <div className={"tab-pane fade" + (pillsTablist === 'pills-group1' ? ' show active' : '')} id="pills-group1" role="tabpanel" aria-labelledby="pills-group-tab1" tabIndex="0">
                           <div className="group-content">
                              <div className="group-list">
                                 <div className="group-list-wrap">
                                    {list.map((item, index) => (
                                       <IntegrationsList 
                                          key={index}
                                          idx={index}
                                          item={item}
                                          onClickHandlerChangeProfileChat={(value) => onClickHandlerChangeProfileChat(value)}
                                       />
                                    ))}
                                    
                                    {/* <div className="item">
                                       <div className="content">
                                          <a href="chat-group.html" className="group-avatar">
                                             <div className="avatar">
                                                <span className="avatar-text"><i className="feather-users"></i></span>
                                             </div>
                                          </a>
                                          <div className="info">
                                             <h6 className="title"><a href="chat-group.html">Designer Group</a></h6>
                                             <p className="text">15 group members</p>
                                          </div>
                                          <div className="action">
                                             <div className="dropdown">
                                                <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="feather-more-vertical"></i>
                                                </button>
                                                <ul className="dropdown-menu">
                                                   <li><a className="dropdown-item" href="#"><i className="feather-edit-2"></i>Edit</a></li>
                                                   <li><a className="dropdown-item" href="#"><i className="feather-bell"></i>Mute</a></li>
                                                   <li>
                                                      <hr className="dropdown-divider" />
                                                   </li>
                                                   <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                                </ul>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="bottom">
                                          <div className="bottom-info">
                                             <span className="group-status"><i className="fas fa-circle"></i> 10 Online</span>
                                          </div>
                                          <div className="avatar-group">
                                             <div className="avatar">
                                                <img src="assets/img/account/14.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <img src="assets/img/account/11.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <img src="assets/img/account/18.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <span className="avatar-text">+5</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="item">
                                       <div className="content">
                                          <a href="chat-group.html" className="group-avatar">
                                             <div className="avatar">
                                                <img src="assets/img/account/group.png" alt="" />
                                             </div>
                                          </a>
                                          <div className="info">
                                             <h6 className="title"><a href="chat-group.html">Designer Group</a></h6>
                                             <p className="text">15 group members</p>
                                          </div>
                                          <div className="action">
                                             <div className="dropdown">
                                                <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="feather-more-vertical"></i>
                                                </button>
                                                <ul className="dropdown-menu">
                                                   <li><a className="dropdown-item" href="#"><i className="feather-edit-2"></i>Edit</a></li>
                                                   <li><a className="dropdown-item" href="#"><i className="feather-bell"></i>Mute</a></li>
                                                   <li>
                                                      <hr className="dropdown-divider" />
                                                   </li>
                                                   <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                                </ul>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="bottom">
                                          <div className="bottom-info">
                                             <span className="group-status"><i className="fas fa-circle text-muted"></i> Offline</span>
                                          </div>
                                          <div className="avatar-group">
                                             <div className="avatar">
                                                <img src="assets/img/account/14.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <img src="assets/img/account/11.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <img src="assets/img/account/18.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <span className="avatar-text">+5</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="item">
                                       <div className="content">
                                          <a href="chat-group.html" className="group-avatar">
                                             <div className="avatar">
                                                <span className="avatar-text"><i className="feather-users"></i></span>
                                             </div>
                                          </a>
                                          <div className="info">
                                             <h6 className="title"><a href="chat-group.html">Designer Group</a></h6>
                                             <p className="text">15 group members</p>
                                          </div>
                                          <div className="action">
                                             <div className="dropdown">
                                                <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="feather-more-vertical"></i>
                                                </button>
                                                <ul className="dropdown-menu">
                                                   <li><a className="dropdown-item" href="#"><i className="feather-edit-2"></i>Edit</a></li>
                                                   <li><a className="dropdown-item" href="#"><i className="feather-bell"></i>Mute</a></li>
                                                   <li>
                                                      <hr className="dropdown-divider" />
                                                   </li>
                                                   <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                                </ul>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="bottom">
                                          <div className="bottom-info">
                                             <span className="group-status"><i className="fas fa-circle"></i> 10 Online</span>
                                          </div>
                                          <div className="avatar-group">
                                             <div className="avatar">
                                                <img src="assets/img/account/14.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <img src="assets/img/account/11.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <img src="assets/img/account/18.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <span className="avatar-text">+5</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="item">
                                       <div className="content">
                                          <a href="chat-group.html" className="group-avatar">
                                             <div className="avatar">
                                                <img src="assets/img/account/group.png" alt="" />
                                             </div>
                                          </a>
                                          <div className="info">
                                             <h6 className="title"><a href="chat-group.html">Designer Group</a></h6>
                                             <p className="text">15 group members</p>
                                          </div>
                                          <div className="action">
                                             <div className="dropdown">
                                                <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="feather-more-vertical"></i>
                                                </button>
                                                <ul className="dropdown-menu">
                                                   <li><a className="dropdown-item" href="#"><i className="feather-edit-2"></i>Edit</a></li>
                                                   <li><a className="dropdown-item" href="#"><i className="feather-bell"></i>Mute</a></li>
                                                   <li>
                                                      <hr className="dropdown-divider" />
                                                   </li>
                                                   <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                                </ul>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="bottom">
                                          <div className="bottom-info">
                                             <span className="group-status"><i className="fas fa-circle"></i> 10 Online</span>
                                          </div>
                                          <div className="avatar-group">
                                             <div className="avatar">
                                                <img src="assets/img/account/14.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <img src="assets/img/account/11.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <img src="assets/img/account/18.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <span className="avatar-text">+5</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="item">
                                       <div className="content">
                                          <a href="chat-group.html" className="group-avatar">
                                             <div className="avatar">
                                                <span className="avatar-text"><i className="feather-users"></i></span>
                                             </div>
                                          </a>
                                          <div className="info">
                                             <h6 className="title"><a href="chat-group.html">Designer Group</a></h6>
                                             <p className="text">15 group members</p>
                                          </div>
                                          <div className="action">
                                             <div className="dropdown">
                                                <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="feather-more-vertical"></i>
                                                </button>
                                                <ul className="dropdown-menu">
                                                   <li><a className="dropdown-item" href="#"><i className="feather-edit-2"></i>Edit</a></li>
                                                   <li><a className="dropdown-item" href="#"><i className="feather-bell"></i>Mute</a></li>
                                                   <li>
                                                      <hr className="dropdown-divider" />
                                                   </li>
                                                   <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                                </ul>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="bottom">
                                          <div className="bottom-info">
                                             <span className="group-status"><i className="fas fa-circle"></i> 10 Online</span>
                                          </div>
                                          <div className="avatar-group">
                                             <div className="avatar">
                                                <img src="assets/img/account/14.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <img src="assets/img/account/11.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <img src="assets/img/account/18.jpg" alt="" />
                                             </div>
                                             <div className="avatar">
                                                <span className="avatar-text">+5</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div> */}

                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className={"tab-pane fade" + (pillsTablist === 'pills-group2' ? ' show active' : '')} id="pills-group2" role="tabpanel" aria-labelledby="pills-group-tab2" tabIndex="0">
                           <div className="group-content">
                              <div className="group-create">
                                 <div className="group-list-wrap">
                                    <div className="label mt-1 mb-3">Telegram</div>
                                    <div className="accordion accordion-flush" id="accordion-group-telegram" style={{marginBottom: "15px"}}>     
                                       {apps.map((item, index) => (
                                          <IntegrationsApps 
                                             key={index}
                                             idx={index}
                                             item={item}
                                             accordion={accordion}
                                             onClickhandleAccordionCollapse={(value) => handleAccordionCollapse(value)}
                                          />
                                       ))}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <div className="tab-pane contact fade" id="pills-sm3" role="tabpanel" aria-labelledby="pills-sm-tab3" tabIndex="0">
               <div className="side-menu-content">
                  <div className="side-menu-header">
                     <h2 className="title">Friends</h2>
                     <div className="contact-invite">
                        <a href="#" className="theme-btn" data-bs-toggle="modal" data-bs-target="#contactInvite"><span className="feather-user-plus"></span>Add Friends</a>
                     </div>
                  </div>
                  <div className="side-menu-search">
                     <form action="#">
                        <div className="input-group">
                           <input type="text" className="form-control" placeholder="Search contacts" />
                           <span className="input-group-text"><i className="feather-search"></i></span>
                        </div>
                     </form>
                  </div>
                  <div className="contact-list scrollbar-wrap">
                     <div className="contact-list-wrap">
                        <div className="label mt-0">A</div>
                        <div className="item">
                           <a href="chat-single.html" className="avatar">
                           <img src="assets/img/account/01.jpg" alt="" />
                           <span className="status away"></span>
                           </a>
                           <div className="info">
                              <h6 className="title"><a href="chat-single.html">Amanda Fusell</a></h6>
                              <p className="text">last seen 5 days ago</p>
                           </div>
                           <div className="action">
                              <div className="dropdown">
                                 <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                 <i className="feather-more-vertical"></i>
                                 </button>
                                 <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#"><i className="feather-share-2"></i>Share</a></li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-slash"></i>Block</a></li>
                                    <li>
                                       <hr className="dropdown-divider" />
                                    </li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="item">
                           <a href="chat-single.html" className="avatar">
                           <span className="avatar-text">AM</span>
                           <span className="status busy"></span>
                           </a>
                           <div className="info">
                              <h6 className="title"><a href="chat-single.html">Aophia Murray</a></h6>
                              <p className="text">is busy now!</p>
                           </div>
                           <div className="action">
                              <div className="dropdown">
                                 <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                 <i className="feather-more-vertical"></i>
                                 </button>
                                 <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#"><i className="feather-share-2"></i>Share</a></li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-slash"></i>Block</a></li>
                                    <li>
                                       <hr className="dropdown-divider" />
                                    </li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="label">B</div>
                        <div className="item">
                           <a href="chat-single.html" className="avatar">
                           <img src="assets/img/account/16.jpg" alt="" />
                           <span className="status online"></span>
                           </a>
                           <div className="info">
                              <h6 className="title"><a href="chat-single.html">Brown Everet</a></h6>
                              <p className="text">is online now</p>
                           </div>
                           <div className="action">
                              <div className="dropdown">
                                 <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                 <i className="feather-more-vertical"></i>
                                 </button>
                                 <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#"><i className="feather-share-2"></i>Share</a></li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-slash"></i>Block</a></li>
                                    <li>
                                       <hr className="dropdown-divider" />
                                    </li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="label">C</div>
                        <div className="item">
                           <a href="chat-single.html" className="avatar">
                           <img src="assets/img/account/08.jpg" alt="" />
                           <span className="status away"></span>
                           </a>
                           <div className="info">
                              <h6 className="title"><a href="chat-single.html">Carley Walker</a></h6>
                              <p className="text">last seen a week ago</p>
                           </div>
                           <div className="action">
                              <div className="dropdown">
                                 <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                 <i className="feather-more-vertical"></i>
                                 </button>
                                 <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#"><i className="feather-share-2"></i>Share</a></li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-slash"></i>Block</a></li>
                                    <li>
                                       <hr className="dropdown-divider" />
                                    </li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="item">
                           <a href="chat-single.html" className="avatar">
                           <img src="assets/img/account/06.jpg" alt="" />
                           <span className="status offline"></span>
                           </a>
                           <div className="info">
                              <h6 className="title"><a href="chat-single.html">Canet Thacker</a></h6>
                              <p className="text">is offline now</p>
                           </div>
                           <div className="action">
                              <div className="dropdown">
                                 <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                 <i className="feather-more-vertical"></i>
                                 </button>
                                 <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#"><i className="feather-share-2"></i>Share</a></li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-slash"></i>Block</a></li>
                                    <li>
                                       <hr className="dropdown-divider" />
                                    </li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="item">
                           <a href="chat-single.html" className="avatar">
                           <img src="assets/img/account/17.jpg" alt="" />
                           <span className="status online"></span>
                           </a>
                           <div className="info">
                              <h6 className="title"><a href="chat-single.html">Crnold Riddick</a></h6>
                              <p className="text">last seen 10 min ago</p>
                           </div>
                           <div className="action">
                              <div className="dropdown">
                                 <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                 <i className="feather-more-vertical"></i>
                                 </button>
                                 <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#"><i className="feather-share-2"></i>Share</a></li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-slash"></i>Block</a></li>
                                    <li>
                                       <hr className="dropdown-divider" />
                                    </li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="label">D</div>
                        <div className="item">
                           <a href="chat-single.html" className="avatar">
                           <img src="assets/img/account/10.jpg" alt="" />
                           <span className="status offline"></span>
                           </a>
                           <div className="info">
                              <h6 className="title"><a href="chat-single.html">Dodma Kathleen</a></h6>
                              <p className="text">last seen a year ago</p>
                           </div>
                           <div className="action">
                              <div className="dropdown">
                                 <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                 <i className="feather-more-vertical"></i>
                                 </button>
                                 <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#"><i className="feather-share-2"></i>Share</a></li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-slash"></i>Block</a></li>
                                    <li>
                                       <hr className="dropdown-divider" />
                                    </li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="label">E</div>
                        <div className="item">
                           <a href="chat-single.html" className="avatar">
                           <img src="assets/img/account/09.jpg" alt="" />
                           <span className="status away"></span>
                           </a>
                           <div className="info">
                              <h6 className="title"><a href="chat-single.html">Eerna Price</a></h6>
                              <p className="text">last seen a month ago</p>
                           </div>
                           <div className="action">
                              <div className="dropdown">
                                 <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                 <i className="feather-more-vertical"></i>
                                 </button>
                                 <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#"><i className="feather-share-2"></i>Share</a></li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-slash"></i>Block</a></li>
                                    <li>
                                       <hr className="dropdown-divider" />
                                    </li>
                                    <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tab-pane notification fade" id="pills-sm4" role="tabpanel" aria-labelledby="pills-sm-tab4" tabIndex="0">
               <div className="side-menu-content">
                  <div className="side-menu-header">
                     <h2 className="title">Notifications <span className="badge rounded-pill">20</span></h2>
                     <div className="dropdown">
                        <a href="#" data-bs-toggle="dropdown" aria-expanded="false"><i className="feather-more-horizontal"></i></a>
                        <ul className="dropdown-menu dropdown-menu-end">
                           <li><a className="dropdown-item" href="#">Clear Today</a></li>
                           <li><a className="dropdown-item" href="#">Clear Previous</a></li>
                           <li><a className="dropdown-item" href="#">Clear All</a></li>
                        </ul>
                     </div>
                  </div>
                  <div className="side-menu-search">
                     <form action="#">
                        <div className="input-group">
                           <input type="text" className="form-control" placeholder="Search notifications" />
                           <span className="input-group-text"><i className="feather-search"></i></span>
                        </div>
                     </form>
                  </div>
                  <div className="notification-list scrollbar-wrap">
                     <div className="notification-list-wrap">
                        <div className="label mt-1">
                           <span>Today</span>
                           <a href="#">Clear all</a>
                        </div>
                        <div className="item">
                           <div className="avatar">
                              <span className="avatar-text theme-bg-warning"><i className="feather-key"></i></span>
                           </div>
                           <div className="info">
                              <div className="title">
                                 <h6>Password</h6>
                                 <span className="time">05:10 PM</span>
                              </div>
                              <div className="body-text">
                                 <p className="text">Your password has been
                                    changed successfully.
                                 </p>
                                 <div className="action">
                                    <div className="dropdown">
                                       <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                       <i className="feather-more-vertical"></i>
                                       </button>
                                       <ul className="dropdown-menu">
                                          <li><a className="dropdown-item" href="#"><i className="feather-check-circle"></i>Mark Read</a></li>
                                          <li><a className="dropdown-item" href="#"><i className="feather-eye-off"></i>Hide</a></li>
                                          <li>
                                             <hr className="dropdown-divider" />
                                          </li>
                                          <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="item">
                           <a href="#" className="avatar">
                           <img src="assets/img/account/05.jpg" alt="" />
                           </a>
                           <div className="info">
                              <div className="title">
                                 <h6><a href="#">Amanda Fusell</a></h6>
                                 <span className="time">05:10 PM</span>
                              </div>
                              <div className="body-text">
                                 <p className="text">Send you a friend request.</p>
                                 <div className="action">
                                    <div className="dropdown">
                                       <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                       <i className="feather-more-vertical"></i>
                                       </button>
                                       <ul className="dropdown-menu">
                                          <li><a className="dropdown-item" href="#"><i className="feather-check-circle"></i>Mark Read</a></li>
                                          <li><a className="dropdown-item" href="#"><i className="feather-eye-off"></i>Hide</a></li>
                                          <li>
                                             <hr className="dropdown-divider" />
                                          </li>
                                          <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                              <div className="bottom">
                                 <a href="#" className="theme-btn">Decline</a>
                                 <a href="#" className="theme-btn">Accept</a>
                              </div>
                           </div>
                        </div>
                        <div className="item">
                           <div className="avatar">
                              <span className="avatar-text theme-bg-primary"><i className="feather-bell"></i></span>
                           </div>
                           <div className="info">
                              <div className="title">
                                 <h6>Systems</h6>
                                 <span className="time">05:10 PM</span>
                              </div>
                              <div className="body-text">
                                 <p className="text">Your account info has been
                                    updated successfully.
                                 </p>
                                 <div className="action">
                                    <div className="dropdown">
                                       <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                       <i className="feather-more-vertical"></i>
                                       </button>
                                       <ul className="dropdown-menu">
                                          <li><a className="dropdown-item" href="#"><i className="feather-check-circle"></i>Mark Read</a></li>
                                          <li><a className="dropdown-item" href="#"><i className="feather-eye-off"></i>Hide</a></li>
                                          <li>
                                             <hr className="dropdown-divider" />
                                          </li>
                                          <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="label">
                           <span>Previous</span>
                           <a href="#">Clear all</a>
                        </div>
                        <div className="item">
                           <a href="#" className="avatar">
                           <img src="assets/img/account/04.jpg" alt="" />
                           </a>
                           <div className="info">
                              <div className="title">
                                 <h6><a href="#">Dodma Kathleen</a></h6>
                                 <span className="time">05:10 PM</span>
                              </div>
                              <div className="body-text">
                                 <p className="text">Send you a private message request in private group.</p>
                                 <div className="action">
                                    <div className="dropdown">
                                       <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                       <i className="feather-more-vertical"></i>
                                       </button>
                                       <ul className="dropdown-menu">
                                          <li><a className="dropdown-item" href="#"><i className="feather-check-circle"></i>Mark Read</a></li>
                                          <li><a className="dropdown-item" href="#"><i className="feather-eye-off"></i>Hide</a></li>
                                          <li>
                                             <hr className="dropdown-divider" />
                                          </li>
                                          <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                              <div className="bottom">
                                 <a href="#" className="theme-btn">Decline</a>
                                 <a href="#" className="theme-btn">Accept</a>
                              </div>
                           </div>
                        </div>
                        <div className="item">
                           <a href="#" className="avatar">
                           <img src="assets/img/account/07.jpg" alt="" />
                           </a>
                           <div className="info">
                              <div className="title">
                                 <h6><a href="#">Canet Thacker</a></h6>
                                 <span className="time">05:10 PM</span>
                              </div>
                              <div className="body-text">
                                 <p className="text">Changed her full name to <br /> <a href="#" className="primary">Aophia Murray</a></p>
                                 <div className="action">
                                    <div className="dropdown">
                                       <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                       <i className="feather-more-vertical"></i>
                                       </button>
                                       <ul className="dropdown-menu">
                                          <li><a className="dropdown-item" href="#"><i className="feather-check-circle"></i>Mark Read</a></li>
                                          <li><a className="dropdown-item" href="#"><i className="feather-eye-off"></i>Hide</a></li>
                                          <li>
                                             <hr className="dropdown-divider" />
                                          </li>
                                          <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="item">
                           <div className="avatar">
                              <span className="avatar-text theme-bg-danger">EP</span>
                           </div>
                           <div className="info">
                              <div className="title">
                                 <h6>Eerna Price</h6>
                                 <span className="time">05:10 PM</span>
                              </div>
                              <div className="body-text">
                                 <p className="text">You missed a video call.</p>
                                 <div className="action">
                                    <div className="dropdown">
                                       <button type="button" className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                       <i className="feather-more-vertical"></i>
                                       </button>
                                       <ul className="dropdown-menu">
                                          <li><a className="dropdown-item" href="#"><i className="feather-check-circle"></i>Mark Read</a></li>
                                          <li><a className="dropdown-item" href="#"><i className="feather-eye-off"></i>Hide</a></li>
                                          <li>
                                             <hr className="dropdown-divider" />
                                          </li>
                                          <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Delete</a></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tab-pane profile fade" id="pills-sm5" role="tabpanel" aria-labelledby="pills-sm-tab5" tabIndex="0">
               <div className="side-menu-content">
                  <div className="side-menu-header">
                     <h2 className="title">Profile</h2>
                  </div>
                  <div className="profile-top">
                     <div className="avatar">
                        <img src="assets/img/account/08.jpg" alt="" />
                        <span className="status online"></span>
                     </div>
                     <h5>Aophia Murray</h5>
                     <p>Web Designer</p>
                  </div>
                  <div className="profile-content scrollbar-wrap">
                     <div className="profile-wrap">
                        <div className="label">
                           Profile Info
                        </div>
                        <div className="profile-info">
                           <ul>
                              <li className="item">
                                 <div className="info">
                                    <h6>Name</h6>
                                    <p>Aophia Murray</p>
                                 </div>
                                 <div className="icon">
                                    <i className="feather-user"></i>
                                 </div>
                              </li>
                              <li className="item">
                                 <div className="info">
                                    <h6>Email</h6>
                                    <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="f39d929e96b3968b929e839f96dd909c9e">[email&#160;protected]</a></p>
                                 </div>
                                 <div className="icon">
                                    <i className="feather-mail"></i>
                                 </div>
                              </li>
                              <li className="item">
                                 <div className="info">
                                    <h6>Phone</h6>
                                    <p>(+2) 123 456 7889</p>
                                 </div>
                                 <div className="icon">
                                    <i className="feather-phone-call"></i>
                                 </div>
                              </li>
                              <li className="item">
                                 <div className="info">
                                    <h6>Location</h6>
                                    <p>Parkway Drive, USA</p>
                                 </div>
                                 <div className="icon">
                                    <i className="feather-globe"></i>
                                 </div>
                              </li>
                              <li className="item">
                                 <div className="info">
                                    <h6>Join Date</h6>
                                    <p>Aug 21, 2024</p>
                                 </div>
                                 <div className="icon">
                                    <i className="feather-calendar"></i>
                                 </div>
                              </li>
                           </ul>
                        </div>
                        <div className="label">
                           Status
                        </div>
                        <div className="profile-info">
                           <ul>
                              <li className="item">
                                 <div className="info">
                                    <h6>Active Status</h6>
                                    <p>Show when you're active.</p>
                                 </div>
                                 <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" checked />
                                 </div>
                              </li>
                              <li className="item">
                                 <div className="info">
                                    <h6>Friends Status</h6>
                                    <p>Show friends status in chat.</p>
                                 </div>
                                 <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" checked />
                                 </div>
                              </li>
                           </ul>
                        </div>
                        <div className="label">
                           Logout
                        </div>
                        <div className="profile-info">
                           <ul>
                              <li>
                                 <a href="#" className="item w-100">
                                    <div className="info">
                                       <h6>Logout</h6>
                                       <p className="text-muted">Sign out from this device.</p>
                                    </div>
                                    <div className="icon">
                                       <i className="feather-log-out text-muted"></i>
                                    </div>
                                 </a>
                              </li>
                           </ul>
                        </div>
                        <div className="label">
                           Social Media
                        </div>
                        <div className="profile-info">
                           <ul>
                              <li className="item">
                                 <div className="info">
                                    <h6>Facebook</h6>
                                    <p>@AophiaMurray</p>
                                 </div>
                                 <div className="icon">
                                    <i className="feather-facebook"></i>
                                 </div>
                              </li>
                              <li className="item">
                                 <div className="info">
                                    <h6>Instagram</h6>
                                    <p>@AophiaMurray</p>
                                 </div>
                                 <div className="icon">
                                    <i className="feather-instagram"></i>
                                 </div>
                              </li>
                              <li className="item">
                                 <div className="info">
                                    <h6>Linkedin</h6>
                                    <p>@AophiaMurray</p>
                                 </div>
                                 <div className="icon">
                                    <i className="feather-linkedin"></i>
                                 </div>
                              </li>
                           </ul>
                        </div>
                        <div className="label">
                           Deactivate
                        </div>
                        <div className="profile-info">
                           <ul>
                              <li className="item">
                                 <div className="info">
                                    <h6>Deactivate Account</h6>
                                    <p>Deactivate your account</p>
                                 </div>
                                 <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" />
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tab-pane setting fade" id="pills-sm6" role="tabpanel" aria-labelledby="pills-sm-tab6" tabIndex="0">
               <div className="side-menu-content">
                  <div className="side-menu-header">
                     <h2 className="title">Settings</h2>
                  </div>
                  <div className="side-menu-search">
                     <form action="#">
                        <div className="input-group">
                           <input type="text" className="form-control" placeholder="Search settings" />
                           <span className="input-group-text"><i className="feather-search"></i></span>
                        </div>
                     </form>
                  </div>
                  <div className="setting-content scrollbar-wrap">
                     <div className="setting-wrap">
                        <div className="label mt-1">Account</div>
                        <div className="item">
                           <div className="accordion accordion-flush" id="accordion-account">
                              <div className="accordion-item">
                                 <div className="accordion-header">
                                    <a href="#" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#account-collapse1" aria-expanded="false" aria-controls="account-collapse1">
                                       <div>
                                          <h6>Profile Info</h6>
                                          <p>Update your profile info</p>
                                       </div>
                                    </a>
                                 </div>
                                 <div id="account-collapse1" className="accordion-collapse collapse" data-bs-parent="#accordion-account">
                                    <div className="accordion-body">
                                       <div className="group-form">
                                          <form action="#">
                                             <div className="avatar group-profile-img">
                                                <img src="assets/img/account/01.jpg" alt="" />
                                                <button type="button" className="gp-img-btn file-btn"><i className="fas fa-plus"></i></button>
                                                <input type="file" className="file-input" />
                                             </div>
                                             <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Name" value="Aophia Murray" />
                                                <span className="input-group-text"><i className="feather-user"></i></span>
                                             </div>
                                             <div className="input-group">
                                                <input type="email" className="form-control" placeholder="Email" value="name@example.com" />
                                                <span className="input-group-text"><i className="feather-mail"></i></span>
                                             </div>
                                             <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Phone" value="1234567889" />
                                                <span className="input-group-text"><i className="feather-phone"></i></span>
                                             </div>
                                             <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Location" value="Parkway Drive, USA" />
                                                <span className="input-group-text"><i className="feather-map-pin"></i></span>
                                             </div>
                                             <div className="input-group">
                                                <textarea className="form-control" cols="30" rows="4" placeholder="Write bio">Web Designer</textarea>
                                             </div>
                                             <div>
                                                <button type="submit" className="theme-btn w-100"><span className="feather-save"></span> Save Changes</button>
                                             </div>
                                          </form>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item">
                                 <div className="accordion-header">
                                    <a href="#" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#account-collapse2" aria-expanded="false" aria-controls="account-collapse2">
                                       <div>
                                          <h6>Social Account</h6>
                                          <p>Connect your social account</p>
                                       </div>
                                    </a>
                                 </div>
                                 <div id="account-collapse2" className="accordion-collapse collapse" data-bs-parent="#accordion-account">
                                    <div className="accordion-body">
                                       <div className="group-form">
                                          <form action="#">
                                             <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Facebook" />
                                                <span className="input-group-text"><i className="feather-facebook"></i></span>
                                             </div>
                                             <div className="input-group">
                                                <input type="email" className="form-control" placeholder="Instagram" />
                                                <span className="input-group-text"><i className="feather-instagram"></i></span>
                                             </div>
                                             <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Linkedin" />
                                                <span className="input-group-text"><i className="feather-linkedin"></i></span>
                                             </div>
                                             <div>
                                                <button type="submit" className="theme-btn w-100"><span className="feather-save"></span> Save Changes</button>
                                             </div>
                                          </form>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Screen Lock</h6>
                                          <p>Enable your screen lock</p>
                                       </div>
                                       <div className="form-check form-switch">
                                          <input className="form-check-input" type="checkbox" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="label">Security</div>
                        <div className="item">
                           <div className="accordion accordion-flush" id="accordion-security">
                              <div className="accordion-item">
                                 <div className="accordion-header">
                                    <a href="#" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#security-collapse1" aria-expanded="false" aria-controls="security-collapse1">
                                       <div>
                                          <h6>Password</h6>
                                          <p>Change your password</p>
                                       </div>
                                    </a>
                                 </div>
                                 <div id="security-collapse1" className="accordion-collapse collapse" data-bs-parent="#accordion-security">
                                    <div className="accordion-body">
                                       <div className="group-form">
                                          <form action="#">
                                             <div className="input-group">
                                                <input type="password" className="form-control" placeholder="Old password" />
                                                <span className="input-group-text"><i className="feather-key"></i></span>
                                             </div>
                                             <div className="input-group">
                                                <input type="email" className="form-control" placeholder="New password" />
                                                <span className="input-group-text"><i className="feather-key"></i></span>
                                             </div>
                                             <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Re-type password" />
                                                <span className="input-group-text"><i className="feather-key"></i></span>
                                             </div>
                                             <div>
                                                <button type="submit" className="theme-btn w-100"><span className="feather-edit-2"></span> Change Password</button>
                                             </div>
                                          </form>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Two-Factor Authentication</h6>
                                          <p>Enable two-factor authentication</p>
                                       </div>
                                       <div className="form-check form-switch">
                                          <input className="form-check-input" type="checkbox" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="label">Privacy</div>
                        <div className="item">
                           <div className="accordion accordion-flush">
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Private profile photo</h6>
                                          <p>Make your profile photo private</p>
                                       </div>
                                       <div className="form-check form-switch">
                                          <input className="form-check-input" type="checkbox" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Last seen</h6>
                                          <p>Show your last active status</p>
                                       </div>
                                       <div className="form-check form-switch">
                                          <input className="form-check-input" type="checkbox" checked />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Friend Invite</h6>
                                          <p>Anyone send friend invitation</p>
                                       </div>
                                       <div className="form-check form-switch">
                                          <input className="form-check-input" type="checkbox" checked />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Media Auto Download</h6>
                                          <p>Your media files auto download</p>
                                       </div>
                                       <div className="form-check form-switch">
                                          <input className="form-check-input" type="checkbox" checked />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="label">Notifications</div>
                        <div className="item">
                           <div className="accordion accordion-flush">
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Notifications</h6>
                                          <p>Enable notifications</p>
                                       </div>
                                       <div className="form-check form-switch">
                                          <input className="form-check-input" type="checkbox" checked />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Message Notifications</h6>
                                          <p>Enable message notifications</p>
                                       </div>
                                       <div className="form-check form-switch">
                                          <input className="form-check-input" type="checkbox" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Call Notifications</h6>
                                          <p>Enable call notifications</p>
                                       </div>
                                       <div className="form-check form-switch">
                                          <input className="form-check-input" type="checkbox" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Notifications Sound</h6>
                                          <p>Enable notifications sound</p>
                                       </div>
                                       <div className="form-check form-switch">
                                          <input className="form-check-input" type="checkbox" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="label">Storage</div>
                        <div className="item">
                           <div className="accordion accordion-flush" id="accordion-storage">
                              <div className="accordion-item">
                                 <div className="accordion-header">
                                    <a href="#" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#storage-collapse1" aria-expanded="false" aria-controls="storage-collapse1">
                                       <div>
                                          <h6>Storage Use</h6>
                                          <p>See your storage use</p>
                                       </div>
                                    </a>
                                 </div>
                                 <div id="storage-collapse1" className="accordion-collapse collapse" data-bs-parent="#accordion-storage">
                                    <div className="accordion-body">
                                       <div className="storage-progress">
                                          <p className="storage-progress-text">Disk uses <span>25%</span> of your storage</p>
                                          <div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                             <div className="progress-bar">25%</div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Auto Backup</h6>
                                          <p>Backup your media files daily</p>
                                       </div>
                                       <div className="form-check form-switch">
                                          <input className="form-check-input" type="checkbox" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <a href="#" className="accordion-button">
                                       <div>
                                          <h6>Clear Storage</h6>
                                          <p>Delete your all media files</p>
                                       </div>
                                       <div className="icon">
                                          <i className="feather-trash-2"></i>
                                       </div>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="label">Last Activity</div>
                        <div className="item">
                           <div className="accordion accordion-flush">
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Windows - Sat 10:15 PM</h6>
                                          <p>Chrome Browser - Parkway, USA</p>
                                       </div>
                                       <span className="danger" data-bs-toggle="tooltip" title="Active Device"><i className="far fa-circle-dot"></i></span>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>iPhone - Sun 12:15 PM</h6>
                                          <p>Chrome Browser - Parkway, USA</p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <div className="accordion-button">
                                       <div>
                                          <h6>Android - Fri 11:25 AM</h6>
                                          <p>Chrome Browser - Parkway, USA</p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <a href="#" className="accordion-button">
                                       <div>
                                          <h6>Clear All Sessions</h6>
                                          <p>Sign out from all devices</p>
                                       </div>
                                       <div className="icon">
                                          <i className="feather-log-out"></i>
                                       </div>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tab-pane help fade" id="pills-sm7" role="tabpanel" aria-labelledby="pills-sm-tab7" tabIndex="0">
               <div className="side-menu-content">
                  <div className="side-menu-header">
                     <h2 className="title">Help</h2>
                  </div>
                  <div className="side-menu-search">
                     <form action="#">
                        <div className="input-group">
                           <input type="text" className="form-control" placeholder="Search help" />
                           <span className="input-group-text"><i className="feather-search"></i></span>
                        </div>
                     </form>
                  </div>
                  <div className="help-content scrollbar-wrap">
                     <div className="help-wrap">
                        <div className="item">
                           <div className="accordion accordion-flush" id="accordion-help">
                              <div className="accordion-item">
                                 <div className="accordion-header">
                                    <a href="#" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#help-collapse1" aria-expanded="false" aria-controls="help-collapse1">
                                       <div>
                                          <h6>Faq's</h6>
                                          <p>Frequently asked questions</p>
                                       </div>
                                    </a>
                                 </div>
                                 <div id="help-collapse1" className="accordion-collapse collapse" data-bs-parent="#accordion-help">
                                    <div className="accordion-body">
                                       <div className="faq-content">
                                          <ul className="faq-list">
                                             <li>
                                                <h6>How to open an account?</h6>
                                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                             </li>
                                             <li>
                                                <h6>Do I need an account?</h6>
                                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                             </li>
                                             <li>
                                                <h6>How to send friend invite?</h6>
                                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                             </li>
                                             <li>
                                                <h6>How to create a group?</h6>
                                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                             </li>
                                             <li>
                                                <h6>How to reset my password?</h6>
                                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                             </li>
                                          </ul>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item">
                                 <div className="accordion-header">
                                    <a href="#" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#help-collapse2" aria-expanded="false" aria-controls="help-collapse2">
                                       <div>
                                          <h6>Contact Us</h6>
                                          <p>Send your message to us</p>
                                       </div>
                                    </a>
                                 </div>
                                 <div id="help-collapse2" className="accordion-collapse collapse" data-bs-parent="#accordion-help">
                                    <div className="accordion-body">
                                       <div className="group-form">
                                          <form action="#">
                                             <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Name" />
                                                <span className="input-group-text"><i className="feather-user"></i></span>
                                             </div>
                                             <div className="input-group">
                                                <input type="email" className="form-control" placeholder="Email" />
                                                <span className="input-group-text"><i className="feather-mail"></i></span>
                                             </div>
                                             <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Subject" />
                                                <span className="input-group-text"><i className="feather-edit-2"></i></span>
                                             </div>
                                             <div className="input-group">
                                                <textarea className="form-control" cols="30" rows="4" placeholder="Your message"></textarea>
                                             </div>
                                             <div>
                                                <button type="submit" className="theme-btn w-100"><span className="feather-send"></span> Send Message</button>
                                             </div>
                                          </form>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="accordion-item accordion-text">
                                 <div className="accordion-header">
                                    <a href="#" className="accordion-button">
                                       <div>
                                          <h6>Live Chat</h6>
                                          <p>Chat with our support team</p>
                                       </div>
                                       <div className="icon">
                                          <i className="feather-message-square"></i>
                                       </div>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div> */}
        </>
    )
}