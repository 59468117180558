import {createAsyncThunk} from '@reduxjs/toolkit'
import {appsIntegrationsAPI} from '../../api/apps'


export const integrationsApps = createAsyncThunk('INTEGRATIONS/APPS', async args => {
	let res = await appsIntegrationsAPI(args)
	return res
})



