import {createSlice} from '@reduxjs/toolkit'
import {dialogsList} from './action/list'
import {dialogsLength} from './action/length'
import {dialogsMessages} from './action/messages'

const initialState = {
	list: null,
	messages: null,
	length: null,
	loading: false,
}

const slice = createSlice({
	name: 'dialogs',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(dialogsList.pending, (state) => {
			state.loading = true
			state.length = null
		})
		builder.addCase(dialogsList.fulfilled, (state, action) => {
			state.list = action.payload
			state.loading = false
		})
		builder.addCase(dialogsLength.pending, (state) => {
			state.loading = true
		})
		builder.addCase(dialogsLength.fulfilled, (state, action) => {
			state.length = action.payload
			state.loading = false
		})
		builder.addCase(dialogsMessages.pending, (state) => {
			state.loading = true
		})
		builder.addCase(dialogsMessages.fulfilled, (state, action) => {
			state.messages = action.payload
			state.loading = false
		})
	}
})

export const {actions : dialogsActions} = slice
export const {reducer : dialogsReducer} = slice
