import {createAsyncThunk} from '@reduxjs/toolkit'
import {listContactsAPI} from '../../api/list'


export const contactsList = createAsyncThunk('CONTACTS/LIST', async args => {
	let res = await listContactsAPI(args)
	return res
})



