import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../modules/User/model/action/login';


export default function Login() {
  const dispatch = useDispatch();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  let authorized = 0;
  let user_id = 0;
  let username = 0;

  let cookie_split = document.cookie.split(';');
  for (let i = 0; i < cookie_split.length; i++) {
    let cookie_name_split = cookie_split[i].split('=');  
    if(cookie_name_split[0].replace(/\s+/g, '') === "authorized"){
      authorized = cookie_name_split[1];
    }
    if(cookie_name_split[0].replace(/\s+/g, '') === "user_id"){
      user_id = cookie_name_split[1];
    }
    if(cookie_name_split[0].replace(/\s+/g, '') === "username"){
      username = cookie_name_split[1];
    }
  }

  // Получаем текущий URL
  const currentUrl = window.location.href;

  // Создаем объект URL
  const url = new URL(currentUrl);

  // Создаем объект URLSearchParams из текущего URL
  const params = new URLSearchParams(url.search);

  // Получаем значение конкретного параметра (например, 'id')
  const id = params.get('id');
  const first_name = params.get('first_name');
  
  // console.log(id);
  if(id == 6738508238 || id == 869719470 || id == 350228580){
    if(id == 869719470){
      user_id = 2
    }
    if(id == 6738508238){
      user_id = 1
    }
    if(id == 350228580){
      user_id = 3
    }
    document.cookie = "authorized=1; path=/;"
    document.cookie = `user_id=${user_id}; path=/;`;
    username = first_name
    document.cookie = `username=${first_name}; path=/;`;
  }

  const handleLogin = (event) => {
    event.preventDefault();
    if (login.trim() !== '' && password.trim() !== '') {
      const data = {
        login: login,
        password: password
      };
      dispatch(loginUser(data))
      .then((res) => {
        user_id = res.payload.id;
        username = res.payload.username;
        if(user_id != 0){
          authorized = 1;
        }
        if(authorized == 1){
          window.location.replace('/dialogs');
        }
      });
    } else {
      console.log('Ошибка авторизации');
    }
  };

  // useEffect(() => {
  //   // console.log(params)
  //   const script = document.createElement('script');
  //   script.src = "https://telegram.org/js/telegram-widget.js?22";
  //   script.async = true;
  //   script.setAttribute('data-telegram-login', 'idevbots_bot');
  //   script.setAttribute('data-size', 'large');
  //   script.setAttribute('data-auth-url', 'https://idevbots.com/login');
  //   script.setAttribute('data-request-access', 'write');
  //   // document.getElementById('telegram-login').appendChild(script);
  // }, [params]);

  // if (authorized == 1 && user_id != 0 && username != 0) {
  //   
  //   return <Navigate to="/dialogs" replace />;
  // }

  // if(authorized == 1){
  //   window.location.replace('/dialogs');
  // }
    
  return (
    <main className="main-layout">
      <div className="auth-area">
        <div className="container">
          <div className="row align-content-center justify-content-center min-vh-100">
            <div className="col-md-7 col-lg-4">
              <div className="auth-form">
                <div className="auth-header">
                  <img src="../assets/img/logo/logo3.png" alt="" />
                  <p>Войдите, чтобы продолжить работу.</p>
                </div>
                <form action="#" className='mb-3'>
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Логин" value={login} onChange={(e) => setLogin(e.target.value)} />
                    <span className="input-group-text"><i className="feather-mail"></i></span>
                  </div>
                  <div className="input-group">
                    <input type="password" className="form-control" placeholder="Пароль" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <span className="input-group-text"><i className="feather-key"></i></span>
                  </div>
                  <div className="auth-btn">
                    <button type="submit" className="theme-btn" onClick={handleLogin}><span className="far fa-sign-in"></span> Войти</button>
                  </div>
                </form>
                <div id="telegram-login"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
